import FsLightbox from '@avantarte/fslightbox-react-fork';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Portal from '../../../components/Portal';
import { SoundCloud } from './internal/SoundCloud';
import { LightboxVimeo, LightboxYoutube } from './mediaTypes/LightboxVimeo';
import { LightboxController, LightboxItem } from './types';
import { useLightbox } from './useLightbox';

export type Props = {
  items?: LightboxItem[];
  children: (lightbox: LightboxController) => ReactNode;
  onSlideChange?: (fromIndex: number, toIndex: number) => void;
  onOpen?: (index: number) => void;
  onClose?: (index: number) => void;
  onEnterFullScreen?: (index: number) => void;
  onExitFullScreen?: (index: number) => void;
};

// used for attaching events to the lightbox dom
const EVENTS_ACCURACY = 500;
const SLIDE_TRACKER_SELECTOR = '.fslightbox-slide-number-container span';
const FULL_SCREEN_BUTTON_SELECTOR =
  '.fslightbox-toolbar div.fslightbox-toolbar-button';

const lightboxItemTypes = (items: LightboxItem[]) => {
  return items.map((item) => (item.media.type === 'IMAGE' ? 'image' : null));
};

const renderLightboxItems = (items: LightboxItem[], autoplayKey: number) => {
  return items.map((item) => {
    switch (item.media.type) {
      case 'IMAGE':
        // Use default fslightbox-react behaviour for images
        return item.media.src;
      case 'VIMEO':
        return (
          <LightboxVimeo {...item.media} autoplay={autoplayKey === item.key} />
        );
      case 'SOUNDCLOUD':
        return <SoundCloud allowAutoplay={false} trackId={item.media.id} />;
      case 'YOUTUBE':
        return (
          <LightboxYoutube
            baseUrl={item.media.baseUrl}
            id={item.media.id}
            ratio={item.media.ratio}
            title={item.media.title}
            type={item.media.type}
          />
        );
      default:
        return null;
    }
  });
};

const lightboxItemCaptions = (items: LightboxItem[]) => {
  return items.map((item) =>
    item.copyright && item.copyright.length > 0 ? (
      <span>&copy; {item.copyright}</span>
    ) : undefined,
  );
};

export const Lightbox: React.FC<Props> = ({
  children,
  items = [],
  onClose,
  onEnterFullScreen,
  onExitFullScreen,
  onOpen,
  onSlideChange,
}) => {
  const controller = useLightbox({ items });
  const [autoplayKey, setAutoplayKey] = useState<number>(0);
  const rootRef = useRef<HTMLDivElement>(null);

  const slideIndex = useMemo(() => {
    return controller.slide !== null
      ? controller.items.findIndex((i) => i.key === controller.slide)
      : 0;
  }, [controller]);

  const types = useMemo(
    () => lightboxItemTypes(controller.items),
    [controller],
  );

  const sources = useMemo(
    () => renderLightboxItems(controller.items, autoplayKey),
    [autoplayKey, controller],
  );

  const captions = useMemo(
    () => lightboxItemCaptions(controller.items),
    [controller],
  );

  const getCurrentSlideIndex = () => {
    const el = rootRef.current?.querySelector(
      SLIDE_TRACKER_SELECTOR,
    ) as HTMLSpanElement;
    // the image numbers start from 1, so in order to get the index we do -1
    const currentIndex = Number(el?.textContent || 0) - 1;
    return currentIndex;
  };

  // listens to changes on the image number element to determine slide change
  const listenToSlideChangeEvents = useCallback(() => {
    let itemIndex = -1;
    const timer = setInterval(() => {
      const currentIndex = getCurrentSlideIndex();
      if (itemIndex !== -1 && currentIndex !== itemIndex) {
        onSlideChange?.(itemIndex, currentIndex);
      }
      itemIndex = currentIndex;
    }, EVENTS_ACCURACY);
    return {
      clear: () => clearInterval(timer),
    };
  }, [onSlideChange]);

  // attaches an event listener to the full screen button
  const listenToFullScreenChange = useCallback(() => {
    const el = rootRef.current?.querySelector(
      FULL_SCREEN_BUTTON_SELECTOR,
    ) as HTMLDivElement;
    el?.addEventListener('click', () => {
      const titleAttribute = el.getAttribute('title');
      if (titleAttribute === 'Enter fullscreen') {
        onEnterFullScreen?.(getCurrentSlideIndex());
      }
      if (titleAttribute === 'Exit fullscreen') {
        onExitFullScreen?.(getCurrentSlideIndex());
      }
    });
    // no need to clear, elements will be removed from the dom with the listener
  }, [onExitFullScreen, onEnterFullScreen]);

  useEffect(() => {
    if (controller.visible && document) {
      document.body.classList.add('pageLocked');
    } else if (!controller.visible && document) {
      document.body.classList.remove('pageLocked');
    }
    if (!controller.visible) {
      return setAutoplayKey(0);
    }

    const clickedSource = items[slideIndex];
    setAutoplayKey(clickedSource.key);

    let slideChangeListener: ReturnType<typeof listenToSlideChangeEvents>;
    if (onSlideChange || onEnterFullScreen || onExitFullScreen) {
      setTimeout(() => {
        if (onSlideChange) {
          slideChangeListener = listenToSlideChangeEvents();
        }
        if (onEnterFullScreen || onExitFullScreen) {
          listenToFullScreenChange();
        }
      }, EVENTS_ACCURACY);
    }

    return () => {
      slideChangeListener?.clear();
    };
  }, [
    controller.visible,
    items,
    listenToFullScreenChange,
    listenToSlideChangeEvents,
    slideIndex,
    onSlideChange,
    onEnterFullScreen,
    onExitFullScreen,
  ]);

  return (
    <>
      {children(controller)}
      {controller.visible && (
        // @types/fslightbox-react is wrong about the `sources` type here.
        // It supports custom elements, not only strings.
        <Portal>
          <div ref={rootRef}>
            <FsLightbox
              key={slideIndex}
              captions={captions}
              onClose={() => {
                controller.setVisible(false);
                onClose?.(slideIndex);
              }}
              onOpen={() => {
                controller.setVisible(true);
                onOpen?.(slideIndex);
              }}
              openOnMount
              sourceIndex={slideIndex}
              sources={sources}
              toggler
              types={types}
            />
          </div>
        </Portal>
      )}
    </>
  );
};
