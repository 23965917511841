import React from 'react';

interface YoutubeProps {
  baseUrl?: string;
  title?: string;
  videoId: string;
}

export const Youtube: React.FC<YoutubeProps> = ({
  baseUrl = 'https://www.youtube.com/embed/',
  title,
  videoId,
}) => (
  <iframe
    allow="autoplay"
    allowFullScreen
    className="h-full w-full"
    src={`${baseUrl}${videoId}?autoplay=1`}
    title={title}
  />
);
