import classnames from 'classnames';
import React from 'react';

type ImageContainerProps = {
  mediaComponent: React.ReactNode;
  href?: string;
  openLinkInTab?: boolean;
};

export const ImageContainer: React.FC<ImageContainerProps> = ({
  href,
  mediaComponent,
  openLinkInTab = false,
}) => {
  const parentTagClasses = 'w-full h-full';
  return href ? (
    <a
      className={parentTagClasses}
      href={href}
      rel="noreferrer"
      target={openLinkInTab ? '_blank' : ''}
    >
      <div className="h-full w-full">{mediaComponent}</div>
    </a>
  ) : (
    <div className={classnames(parentTagClasses)}>{mediaComponent}</div>
  );
};
