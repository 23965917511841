import { ImageSizes } from '../../../../helpers/images';
import { CardWrapper } from '../../Containers';
import { Pill } from '../../Pill';

type Props = {
  image: {
    filename: string;
    alt?: string;
    copyright?: string;
    focus?: string;
  };
  imageSize: ImageSizes;
  title: string;
  description: string;
  pillLabel?: string;
};

export const InterestsCard = ({
  description,
  image,
  imageSize,
  pillLabel,
  title,
}: Props) => {
  return (
    <CardWrapper image={image} imageSize={imageSize} variant="image">
      <span className="flex aspect-[4/3] min-h-48 flex-col items-start justify-between gap-3 p-4 md:p-6">
        <span>{pillLabel && <Pill label={pillLabel} />}</span>
        <span className="flex w-full max-w-xl flex-col">
          <span className="text-pretty font-bold">{title}</span>
          <span className="text-pretty">{description}</span>
        </span>
      </span>
    </CardWrapper>
  );
};
