import classNames from 'classnames';
import React, { forwardRef } from 'react';

import styles from './checkbox.module.css';

interface CheckboxButtonProps {
  type: 'checkbox' | 'radio';
  id: string;
  label: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  description?: string;
  suffix?: string;
  dataTestid?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const CheckboxButton = forwardRef<HTMLInputElement, CheckboxButtonProps>(
  (
    {
      checked = false,
      dataTestid,
      disabled = false,
      id,
      label,
      name,
      onChange,
      size = 'xs',
      type = 'checkbox',
      value,
      ...rest
    },
    ref,
  ) => {
    const sizeMapper = {
      'xs': 'p-1',
      'sm': 'p-2',
      'md': 'p-3',
      'lg': 'p-4',
    };

    return (
      <label
        className={classNames(
          checked
            ? styles.UiCheckboxButton__checked
            : styles.UiCheckboxButton__default,
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
          sizeMapper[size],
        )}
        htmlFor={id}
      >
        <span className={classNames(styles.UiCheckboxButton__label)}>
          {label}
        </span>

        <input
          ref={ref}
          checked={checked}
          className={classNames(styles.UiCheckboxButton__input)}
          data-testid={dataTestid}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          type={type}
          value={value}
          {...rest}
        />
      </label>
    );
  },
);
