import classNames from 'classnames';
import React, { FC } from 'react';

import { Icon } from '../../Icon';
import styles from './switch.module.css';

interface SwitchIconProps {
  isChecked: boolean;
  isDisabled: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const SwitchIcon: FC<SwitchIconProps> = ({
  isChecked,
  isDisabled,
  size = 'xs',
}) => {
  const sizeContainerMapper = {
    'xs': 'w-10',
    'sm': 'w-12',
    'md': 'w-16',
    'lg': 'w-24',
  };

  const sizeMapper = {
    'xs': 'h-5 w-5',
    'sm': 'h-6 w-6',
    'md': 'h-8 w-8',
    'lg': 'h-12 w-12',
  };

  return (
    <span
      className={classNames(styles.UiSwitchIcon, sizeContainerMapper[size], {
        'cursor-not-allowed opacity-50': isDisabled,
      })}
    >
      <span
        className={classNames(
          isChecked
            ? styles.UiSwitchIcon__CheckIcon__checked
            : styles.UiSwitchIcon__CheckIcon__default,
          isChecked ? 'translate-x-full' : 'translate-x-0',
          sizeMapper[size],
          'rounded-full',
        )}
      >
        <Icon
          className={classNames(
            styles.UiSwitchIcon__CheckIcon__icon,
            isChecked ? 'scale-100 opacity-100' : 'scale-0 opacity-0',
          )}
          name="ic_check"
        />
      </span>
    </span>
  );
};
