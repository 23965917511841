import classNames from 'classnames';

import styles from './divider.module.css';

interface DividerProps {
  variant?: 'dashed' | 'dotted' | 'solid';
  className?: string;
}

export const Divider = ({ className, variant = 'solid' }: DividerProps) => (
  <hr
    className={classNames(
      variant === 'solid' && styles.UiDivider__solid,
      variant === 'dashed' && styles.UiDivider__dashed,
      variant === 'dotted' && styles.UiDivider__dotted,
      className,
    )}
  />
);
