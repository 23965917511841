import classNames from 'classnames';

import styles from './badge.module.css';

type Props = {
  label: string;
};

export const Badge = ({ label }: Props) => {
  return (
    <span className={classNames(styles.UiBadge)}>
      <span className={classNames(styles.UiBadge__label)}>{label}</span>
    </span>
  );
};
