import classNames from 'classnames';
import { FC } from 'react';

import { Ratio } from '../../helpers/images';

interface AspectRatioProps {
  className?: string;
  lg?: Ratio;
  md?: Ratio;
  onClick?: () => void;
  sm?: Ratio;
  children?: React.ReactNode;
}

export const AspectRatio: FC<AspectRatioProps> = ({
  children,
  className,
  lg,
  md,
  onClick,
  sm,
}) => {
  if (typeof onClick === 'function') {
    return (
      <button
        className={classNames(
          'relative block w-full',
          sm && 'aspect-[var(--aspect-sm)]',
          md && 'md:aspect-[var(--aspect-md)]',
          lg && 'lg:aspect-[var(--aspect-lg)]',
          className,
        )}
        onClick={onClick}
        style={
          {
            '--aspect-sm': sm,
            '--aspect-md': md,
            '--aspect-lg': lg,
          } as React.CSSProperties
        }
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <div
      className={classNames(
        'relative block w-full',
        sm && 'aspect-[var(--aspect-sm)]',
        md && 'md:aspect-[var(--aspect-md)]',
        lg && 'lg:aspect-[var(--aspect-lg)]',
        className,
      )}
      style={
        {
          '--aspect-sm': sm,
          '--aspect-md': md,
          '--aspect-lg': lg,
        } as React.CSSProperties
      }
    >
      {children}
    </div>
  );
};
