import classNames from 'classnames';

import { Image } from '../../../../components/Image';
import { ImageSizes } from '../../../../helpers/images';
import styles from '../containers.module.css';

interface CardWrapperBase {
  children: React.ReactNode;
}

type CardWrapperSolid = CardWrapperBase & {
  variant: 'primary' | 'secondary' | 'tertiary';
};

type CardWrapperImage = CardWrapperBase & {
  variant: 'image';
  image: {
    filename: string;
    alt?: string;
    copyright?: string;
    focus?: string;
  };
  imageSize?: ImageSizes;
  imageWidth?: number;
  hideOverlay?: boolean;
};

type CardPattern = CardWrapperSolid | CardWrapperImage;

const isImage = (props: CardPattern): props is CardWrapperImage => {
  return props.variant === 'image';
};

export const CardWrapper = (props: CardPattern) => {
  if (isImage(props)) {
    const { children, hideOverlay = false, image } = props;

    return (
      <div className={classNames(styles.UiCard__variant__image)}>
        <div className={classNames(styles.UiCard__image)}>
          <Image
            alt={image.alt || ''}
            className="h-full w-full object-cover"
            focus={image?.focus}
            hoverEffect
            src={image.filename}
          />
        </div>
        {!hideOverlay ? (
          <div className={classNames(styles.UiCard__overlay)} />
        ) : null}
        <div className="relative h-full w-full">{children}</div>
      </div>
    );
  }

  const { children, variant } = props;

  return (
    <div
      className={classNames(
        variant === 'primary' && styles.UiCard__variant__primary,
        variant === 'secondary' && styles.UiCard__variant__secondary,
        variant === 'tertiary' && styles.UiCard__variant__tertiary,
      )}
    >
      <div className="relative h-full w-full">{children}</div>
    </div>
  );
};
