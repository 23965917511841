import React from 'react';

import { PageNumber } from './PageNumber';

type PageNumbersFragmentProps = {
  start: number;
  end: number;
  currentPage: number;
  paginationUrl: string;
  queryParams?: string;
  handleOnClick?: (page: number) => void;
};

const PageNumbersFragment: React.FC<PageNumbersFragmentProps> = ({
  currentPage,
  end,
  handleOnClick,
  paginationUrl,
  queryParams,
  start,
}: PageNumbersFragmentProps) => {
  if (start > end) return null;

  if (start === end) {
    return (
      <PageNumber
        handleOnClick={handleOnClick}
        isCurrentPage={start === currentPage}
        page={start}
        paginationUrl={paginationUrl}
        queryParams={queryParams}
      />
    );
  }

  return (
    <>
      <PageNumber
        handleOnClick={handleOnClick}
        isCurrentPage={start === currentPage}
        page={start}
        paginationUrl={paginationUrl}
        queryParams={queryParams}
      />
      <PageNumbersFragment
        currentPage={currentPage}
        end={end}
        handleOnClick={handleOnClick}
        paginationUrl={paginationUrl}
        queryParams={queryParams}
        start={start + 1}
      />
    </>
  );
};

type Props = {
  numPages: number;
  currentPage: number;
  paginationUrl: string;
  queryParams?: string;
  handleOnClick?: (page: number) => void;
};

export const PageNumbers: React.FC<Props> = ({
  currentPage,
  handleOnClick,
  numPages,
  paginationUrl,
  queryParams,
}) => {
  const ellipsis = (
    <div className="hidden h-9 w-9 items-center justify-center text-center sm:flex">
      <p className="text-neutral-4">...</p>
    </div>
  );

  if (numPages < 7) {
    return (
      <PageNumbersFragment
        currentPage={currentPage}
        end={numPages}
        handleOnClick={handleOnClick}
        paginationUrl={paginationUrl}
        queryParams={queryParams}
        start={1}
      />
    );
  }

  if (currentPage <= 4) {
    return (
      <>
        <PageNumbersFragment
          currentPage={currentPage}
          end={currentPage + 2}
          handleOnClick={handleOnClick}
          paginationUrl={paginationUrl}
          queryParams={queryParams}
          start={1}
        />
        {ellipsis}
        <PageNumbersFragment
          currentPage={currentPage}
          end={numPages}
          handleOnClick={handleOnClick}
          paginationUrl={paginationUrl}
          queryParams={queryParams}
          start={numPages}
        />
      </>
    );
  }

  if (currentPage >= numPages - 3) {
    return (
      <>
        <PageNumbersFragment
          currentPage={currentPage}
          end={1}
          handleOnClick={handleOnClick}
          paginationUrl={paginationUrl}
          queryParams={queryParams}
          start={1}
        />
        {ellipsis}
        <PageNumbersFragment
          currentPage={currentPage}
          end={numPages}
          handleOnClick={handleOnClick}
          paginationUrl={paginationUrl}
          queryParams={queryParams}
          start={currentPage - 2}
        />
      </>
    );
  }

  return (
    <>
      <PageNumbersFragment
        currentPage={currentPage}
        end={1}
        handleOnClick={handleOnClick}
        paginationUrl={paginationUrl}
        queryParams={queryParams}
        start={1}
      />
      {ellipsis}
      <PageNumbersFragment
        currentPage={currentPage}
        end={currentPage + 2}
        handleOnClick={handleOnClick}
        paginationUrl={paginationUrl}
        queryParams={queryParams}
        start={currentPage - 2}
      />
      {ellipsis}
      <PageNumbersFragment
        currentPage={currentPage}
        end={numPages}
        handleOnClick={handleOnClick}
        paginationUrl={paginationUrl}
        queryParams={queryParams}
        start={numPages}
      />
    </>
  );
};
