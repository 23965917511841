import classNames from 'classnames';
import React, { forwardRef } from 'react';

import styles from './switch.module.css';
import { SwitchIcon } from './SwitchIcon';

interface SwitchProps {
  id: string;
  label: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  description?: string;
  suffix?: string;
  dataTestid?: string;
}

const SwitchCardItem = ({
  description,
  label,
  suffix,
}: {
  description?: string;
  label: string;
  suffix?: string;
}) => (
  <span className="flex w-full gap-3">
    <span className={classNames(styles.UiSwitch__label)}>
      <span className={classNames(styles.UiSwitch__label__value)}>{label}</span>

      {description ? (
        <span className={classNames(styles.UiSwitch__label__description)}>
          {description}
        </span>
      ) : null}
    </span>

    {suffix ? (
      <span className={classNames(styles.UiSwitch__suffix)}>{suffix}</span>
    ) : null}
  </span>
);

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      checked = false,
      dataTestid,
      description,
      disabled = false,
      id,
      label,
      name,
      onChange,
      suffix,
      value,
      ...rest
    },
    ref,
  ) => {
    return (
      <label
        className={classNames(
          checked ? styles.UiSwitch__checked : styles.UiSwitch__default,
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        )}
        htmlFor={id}
      >
        <SwitchCardItem
          description={description}
          label={label}
          suffix={suffix}
        />

        <span className="relative h-full shrink-0">
          <input
            ref={ref}
            checked={checked}
            className={classNames(styles.UiSwitch__input)}
            data-testid={dataTestid}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            type="checkbox"
            value={value}
            {...rest}
          />

          <SwitchIcon isChecked={checked} isDisabled={disabled} />
        </span>
      </label>
    );
  },
);
