import React, { forwardRef } from 'react';

import { Icon } from '../../Icon';
import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

interface Option
  extends Pick<React.OptionHTMLAttributes<HTMLOptionElement>, 'disabled'>,
    Pick<React.ReactElement, 'key'> {
  value: string | number;
}

interface SelectFieldProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'prefix'> {
  options: Option[];
  label?: string;
  error?: string;
  note?: string | React.ReactNode;
  hideErrorMessage?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  dataTestid?: string;
  placeholder?: string;
  textAlign?: 'center' | 'left';
}

export const selectOptionsEnum = (
  enumObj: Record<string, string>,
  toExclude?: string[],
) => {
  return Object.values(enumObj)
    .filter((v) => !toExclude?.includes(v))
    .map((v) => ({ key: v, value: v }));
};

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      dataTestid,
      disabled = false,
      error,
      hideErrorMessage = false,
      id,
      label,
      name,
      note,
      options,
      placeholder,
      prefix,
      required = false,
      suffix,
      textAlign = 'left',
      ...rest
    },
    ref,
  ) => {
    return (
      <FieldWrapper
        disabled={disabled}
        error={error}
        hideError={hideErrorMessage}
        id={id}
        label={label}
        note={note}
      >
        {prefix && <span>{prefix}</span>}
        <div className="relative w-full">
          <select
            ref={ref}
            className={textAlign === 'center' ? 'text-center' : 'text-left'}
            data-testid={dataTestid}
            disabled={disabled}
            id={id}
            name={name}
            required={required}
            {...rest}
          >
            {placeholder && (
              <option disabled value="">
                {placeholder}
              </option>
            )}
            {options.map((option) => (
              <option
                key={option.value}
                disabled={option.disabled}
                value={option.value}
              >
                {option.key}
              </option>
            ))}
          </select>
          <Icon className="absolute right-0 top-3" name="ic_chevron_down" />
        </div>
        {suffix && <span>{suffix}</span>}
      </FieldWrapper>
    );
  },
);
