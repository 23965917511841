import { forwardRef } from 'react';

type Props = {
  className?: string;
  id: string;
  offset?: number;
};

/**
 * ScrollAnchor element which accounts for the size of the navigation bar,
 * and some padding to offset the screen a bit.
 */
const ScrollAnchor = forwardRef<HTMLAnchorElement, Props>(
  ({ className = 'absolute -top-18 md:-top-24', id, offset = 0 }, ref) => {
    return (
      <div className="relative">
        <a
          ref={ref}
          className={className}
          id={id}
          style={{ marginTop: offset }}
        />
      </div>
    );
  },
);

export default ScrollAnchor;
