import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import BackgroundVideo from '../../../../components/BackgroundVideo';
import { Image } from '../../../../components/Image';
import ScrollAnchor from '../../../../components/ScrollAnchor';
import { ImageSizes } from '../../../../helpers/images';
import { usePrefersReducedMotion } from '../../../../hooks/usePrefersReducedMotion';
import { StoryblokVideo } from '../../../../types/Vimeo';
import { storyblokMediaItemToLightboxEntry } from '../../../../utils/lightbox';
import { Lightbox } from '../../Lightbox';
import { MediaContainer } from '../../MediaContainer';
import carouselStyles from '../splide.module.css';

type Props = {
  heroImageRef: React.RefObject<HTMLAnchorElement>;
  images: Array<
    | ({
        component: 'Video';
        quote?: Quote | undefined;
        _uid: string;
        showCopyright: boolean;
      } & StoryblokVideo)
    | {
        component: 'image';
        image: StoryblokImage;
        caption?: string;
        showCopyright: boolean;
        _uid: string;
      }
  >;
  onClick?: (properties: Record<string, string | number>) => void;
  onSlide?: (index: number) => void;
  resetTrigger?: number;
};

export const ProductImageGallery: React.FC<Props> = ({
  heroImageRef,
  images,
  onClick,
  onSlide = () => {},
  resetTrigger,
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const lightboxItems = React.useMemo(
    () => images.map(storyblokMediaItemToLightboxEntry),
    [images],
  );

  const mainRef = React.useRef<Splide>(null);

  useEffect(() => {
    const splideElement = mainRef.current;

    if (splideElement) {
      splideElement.go(0);
    }
  }, [images, resetTrigger]);

  return (
    <Lightbox items={lightboxItems}>
      {(lightbox) => (
        <Splide
          ref={mainRef}
          hasTrack={false}
          onMoved={(e) => {
            onSlide(e.index);
          }}
          options={{
            type: 'fade',

            rewind: false,
            pagination: true,
            mediaQuery: 'min',

            arrows: false,
            focus: 0,
            gap: 0,
            perMove: 1,
            perPage: 1,

            flickPower: 300,
            flickMaxPages: 0.3,
            dragMinThreshold: {
              mouse: 10,
              touch: 30,
            },

            breakpoints: {
              768: {
                destroy: true,
              },
            },
            classes: {
              pagination: 'splide__pagination gap-2 pt-3',
              page: 'splide__pagination__page block w-1.5 h-1.5 bg-neutral-6/10 hover:bg-neutral-6/40 [&.is-active]:bg-neutral-6 rounded-full transition duration-300 ease-out',
            },
          }}
        >
          <div className={classNames(carouselStyles.UiProductImageGallery)}>
            <SplideTrack
              className={classNames(
                carouselStyles.UiProductImageGallery__track,
              )}
            >
              {images.map((media, index) => {
                const sizes = {
                  sm: { ratio: '1/1', span: 12 },
                  md: { ratio: '1/1', span: index === 0 ? 6 : 4 },
                  lg: { ratio: '1/1', span: index === 0 ? 6 : 4 },
                } as ImageSizes;

                if (media.component === 'image') {
                  return (
                    <SplideSlide
                      key={media._uid}
                      className={classNames(
                        carouselStyles.UiProductImageGallery__track__slide,
                      )}
                    >
                      <MediaContainer
                        mediaComponent={
                          <Image
                            alt={media.image.alt}
                            focus={media.image.focus}
                            hoverEffect
                            onClick={() => {
                              lightbox.openOnSlide(index);
                              onClick?.({
                                position: index,
                                title: media.image.title,
                                type: 'image',
                              });
                            }}
                            size={sizes}
                            src={media.image.filename}
                          />
                        }
                        type="Image"
                      />
                      {index === 0 && (
                        <ScrollAnchor
                          ref={heroImageRef}
                          className="absolute"
                          id="heroImage"
                        />
                      )}
                    </SplideSlide>
                  );
                }

                const videoIsConfigured = media.vimeo.vimeo_oembed !== null;
                const autoplayVideo =
                  index === 0 && !prefersReducedMotion && videoIsConfigured;

                return (
                  <SplideSlide
                    key={media._uid}
                    className={classNames(
                      carouselStyles.UiProductImageGallery__track__slide,
                    )}
                  >
                    {autoplayVideo ? (
                      <button
                        className="group relative block aspect-[1/1] w-full bg-white"
                        onClick={() => {
                          lightbox.openOnSlide(index);
                          onClick?.({
                            position: index,
                            title: media.vimeo.vimeo_oembed.response.title,
                            type: 'video',
                            durationInSeconds:
                              media.vimeo?.vimeo_oembed.response.duration,
                          });
                        }}
                        type="button"
                      >
                        <BackgroundVideo
                          cover={media.coverImage}
                          vimeo={media.vimeo}
                        />
                      </button>
                    ) : (
                      <MediaContainer
                        duration={media.vimeo?.vimeo_oembed.response.duration}
                        mediaComponent={
                          <Image
                            alt={media.coverImage.alt}
                            eagerLoading={index === 0}
                            focus={media.coverImage.focus}
                            size={sizes}
                            src={media.coverImage.filename}
                          />
                        }
                        onClick={() => {
                          lightbox.openOnSlide(index);
                          onClick?.({
                            position: index,
                            title: media.vimeo.vimeo_oembed.response.title,
                            type: 'video',
                            durationInSeconds:
                              media.vimeo?.vimeo_oembed.response.duration,
                          });
                        }}
                        title={media.title}
                        type="Video"
                      />
                    )}
                    {index === 0 && (
                      <ScrollAnchor
                        ref={heroImageRef}
                        className="absolute"
                        id="heroImage"
                      />
                    )}
                  </SplideSlide>
                );
              })}
            </SplideTrack>
          </div>
        </Splide>
      )}
    </Lightbox>
  );
};
