import classNames from 'classnames';
import React from 'react';

import { Icon } from '../Icon';
import styles from './alert.module.css';

export interface AlertProps {
  status: 'error' | 'success' | 'info';
}

export const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({
  children,
  status,
}) => {
  const mapIcon = {
    success: 'ic_check_circle',
    error: 'ic_error',
    info: 'ic_info',
  } as const;

  return (
    <div
      className={classNames(
        status === 'info' && styles.UiAlert__info,
        status === 'success' && styles.UiAlert__positive,
        status === 'error' && styles.UiAlert__danger,
      )}
    >
      <Icon
        className={classNames(styles.UiAlert__icon)}
        name={mapIcon[status]}
        size="sm"
      />
      <div className="flex-1">{children}</div>
    </div>
  );
};
