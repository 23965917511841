import classNames from 'classnames';
import {
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer-ts';

type Options = {
  paragraphStyle: 'regular' | 'tight';
  paragraphClassName: string;
  leadingParagraphIsHighlight?: boolean;
};

const DEFAULT_OPTIONS: Options = {
  paragraphStyle: 'regular',
  paragraphClassName: 'pb-4',
};

export const renderStoryblokRichText = (
  text: StoryblokTextAreaType,
  options: Partial<Options> = {},
) => {
  const opts = { ...DEFAULT_OPTIONS, ...options };

  return render(text, {
    markResolvers: {
      [MARK_LINK]: (children: any, props: any) => {
        if (props?.linktype === 'email' && props?.href)
          return (
            <a className="hyperlink" href={`mailto:${props.href}`}>
              {children}
            </a>
          );

        return (
          <a {...props} className="hyperlink">
            {children}
          </a>
        );
      },
      [MARK_BOLD]: (children: any, props: any) => (
        <text {...props} className="font-bold">
          {children}
        </text>
      ),
    },
    nodeResolvers: {
      [NODE_UL]: (children: any, props: any) => (
        <ul className="rich-text-list py-2" {...props}>
          {children}
        </ul>
      ),
      [NODE_PARAGRAPH]: (children: any, props: any) => (
        <p
          {...props}
          className={classNames(opts.paragraphClassName, {
            'pb-3 last-of-type:inline last-of-type:pb-0':
              opts.paragraphStyle === 'tight',
            'first-of-type:text-md': opts.leadingParagraphIsHighlight,
          })}
        >
          {children}
        </p>
      ),
    },
  });
};

export const isStoryblokRichTextPresent = (text: any) => {
  if (!text) return false;
  if (typeof text === 'string') return text.trim().length > 0;

  return text?.content?.some((content: any) => !!content?.content) || false;
};

export const countStoryblokRichTextChars = (doc: StoryblokRichtext): number => {
  return (doc?.content || []).reduce((acc, curr) => {
    let count = 0;
    (curr.content || []).forEach((node) => {
      count += node.text?.length || 0;
    });
    return acc + count;
  }, 0);
};

export const truncateStoryblokRichText = (
  doc: StoryblokRichtext,
  chars: number,
): StoryblokRichtext => {
  let charsLeft = chars;

  const content = (doc?.content || []).reduce((acc, curr) => {
    if (charsLeft <= 0) return acc;
    return [
      ...acc,
      {
        ...curr,
        content: (curr.content || []).reduce((node, paragraph) => {
          if (charsLeft <= 0) return node;
          let text = paragraph.text?.slice(0, charsLeft) || '';
          charsLeft -= text.length;
          if (charsLeft <= 0) {
            text = text.trimEnd();
            text += text.endsWith('.') ? '.. ' : '... ';
          }
          return [...node, { ...paragraph, text }];
        }, []),
      },
    ];
  }, []);

  return { type: 'doc', content };
};

export const getFirstStoryblokParagraph = (doc?: StoryblokTextAreaType) => {
  const truncated: StoryblokTextAreaType = {
    type: 'doc',
    content: [],
  };

  if (doc === undefined) return truncated;

  truncated.content.push(
    doc.content.find(
      (c: any) => c.type === 'paragraph' && c.content?.length > 0,
    ),
  );

  return truncated;
};

export const stringToStoryblokRichText = (value = ''): StoryblokRichtext => {
  const content = value.split('\n').map((text) => ({
    type: 'paragraph',
    content: [{ content: {}, type: 'text', text }],
  }));
  return { type: 'doc', content } as StoryblokRichtext;
};
