import classNames from 'classnames';
import React from 'react';

export const iconSet = {
  'ic_account':
    'M10 9.744q-1.078 0-1.83-.753a2.5 2.5 0 0 1-.753-1.83q0-1.08.752-1.831A2.5 2.5 0 0 1 10 4.577q1.079 0 1.83.753.753.752.753 1.83 0 1.08-.752 1.83A2.5 2.5 0 0 1 10 9.745m-5.583 5.679v-1.52q0-.382.228-.737t.639-.615a10 10 0 0 1 2.295-.96A9 9 0 0 1 10 11.256q1.215 0 2.422.335a10 10 0 0 1 2.294.96q.411.239.639.604t.228.749v1.519z',
  'ic_add':
    'M9.458 10.542H5.417V9.458h4.041V5.417h1.084v4.041h4.041v1.084h-4.041v4.041H9.458z',
  'ic_apple':
    'M16.085 7.454c-.093.072-1.728.996-1.728 3.048 0 2.375 2.08 3.215 2.143 3.236-.01.05-.33 1.15-1.097 2.27-.684.986-1.397 1.97-2.483 1.97s-1.365-.632-2.619-.632c-1.222 0-1.656.652-2.65.652-.992 0-1.685-.912-2.482-2.032C4.246 14.651 3.5 12.608 3.5 10.67c0-3.11 2.018-4.76 4.005-4.76 1.055 0 1.935.694 2.598.694.63 0 1.614-.736 2.815-.736.455 0 2.09.042 3.167 1.587M12.348 4.55c.497-.59.848-1.41.848-2.228 0-.114-.01-.23-.03-.322-.808.03-1.77.54-2.35 1.213-.454.518-.88 1.337-.88 2.168 0 .125.022.25.031.29.051.009.134.02.217.02.725 0 1.637-.486 2.164-1.14',
  'ic_arrow_down':
    'M9.458 4.417v9.085L5.191 9.234 4.417 10 10 15.583 15.583 10l-.774-.766-4.267 4.268V4.417z',
  'ic_arrow_left':
    'm6.498 10.542 4.268 4.267-.766.774L4.417 10 10 4.417l.766.774-4.268 4.267h9.085v1.084z',
  'ic_arrow_right':
    'M13.502 10.542H4.417V9.458h9.085L9.234 5.191 10 4.417 15.583 10 10 15.583l-.766-.774z',
  'ic_arrow_top_right':
    'm4.974 14.703-.766-.766 8.638-8.645H5.12V4.208h9.584v9.583H13.62V6.067z',
  'ic_arrow_up':
    'M9.458 15.583V6.498l-4.267 4.268L4.417 10 10 4.417 15.583 10l-.774.766-4.267-4.268v9.085z',
  'ic_attach':
    'M14.228 13.412q0 1.735-1.206 2.953t-2.938 1.218q-1.755 0-2.953-1.268t-1.198-3.049V5.378q0-1.234.855-2.097.857-.864 2.09-.864 1.255 0 2.1.916.846.915.846 2.191v7.404q0 .724-.505 1.238a1.66 1.66 0 0 1-1.23.514q-.742 0-1.248-.534a1.82 1.82 0 0 1-.504-1.301V5.32H9.42v7.607q0 .28.19.474.19.195.47.194a.63.63 0 0 0 .47-.194.65.65 0 0 0 .19-.474V5.37a1.78 1.78 0 0 0-.537-1.323A1.8 1.8 0 0 0 8.874 3.5q-.78 0-1.32.571a1.9 1.9 0 0 0-.538 1.349v7.992q.007 1.282.9 2.175.89.893 2.171.913t2.177-.924q.896-.945.88-2.268V5.32h1.083z',
  'ic_bolt':
    'm7.48 17.535 1-5.452H4.794l6.117-9.578h.86l-.743 6.412h4.084L8.34 17.535z',
  'ic_bookmark_filled':
    'M5.417 16.375V4.756q0-.569.385-.954.386-.385.954-.385h6.488q.568 0 .954.385.385.385.385.954v11.619L10 14.551z',
  'ic_bookmark_outline':
    'M5.417 16.375V4.756q0-.563.388-.95.39-.39.951-.39h6.488q.563 0 .95.39.39.387.39.95v11.619L10 14.551zM6.5 14.771l3.5-1.396 3.5 1.396V4.757a.25.25 0 0 0-.08-.177.25.25 0 0 0-.176-.08H6.756a.25.25 0 0 0-.176.08.25.25 0 0 0-.08.176z',
  'ic_call':
    'M15.471 16.562a12.3 12.3 0 0 1-4.368-1.2 13.9 13.9 0 0 1-3.8-2.715 13.8 13.8 0 0 1-2.697-3.795 12.4 12.4 0 0 1-1.19-4.365.95.95 0 0 1 .261-.764.97.97 0 0 1 .74-.327h2.176q.378 0 .65.227.273.225.38.592l.436 1.764q.05.28-.031.545a.94.94 0 0 1-.264.433L5.84 8.83q.465.871 1.03 1.641t1.24 1.44a12.5 12.5 0 0 0 3.106 2.264l1.975-1.928a.86.86 0 0 1 .4-.244q.227-.06.505-.02l1.668.368q.379.104.599.384.22.282.22.667v2.16a.96.96 0 0 1-.337.74.98.98 0 0 1-.775.26',
  'ic_calendar':
    'M4.757 17.583q-.564 0-.952-.395a1.3 1.3 0 0 1-.388-.944V5.756q0-.548.388-.944.389-.395.952-.395h1.82V2.32h1.115v2.096h4.648V2.32h1.083v2.096h1.82q.564 0 .952.395a1.3 1.3 0 0 1 .388.944v10.488q0 .548-.388.944-.39.395-.951.395zm0-1.083h10.487a.25.25 0 0 0 .176-.08.25.25 0 0 0 .08-.177V9.257h-11v6.988q0 .096.08.176t.177.08',
  'ic_camera':
    'M10 14.18q1.372 0 2.276-.925T13.18 11q0-1.309-.904-2.244T10 7.82t-2.276.935A3.12 3.12 0 0 0 6.821 11q0 1.33.903 2.255.904.924 2.276.925m0-1.084q-.923 0-1.51-.586-.585-.587-.586-1.49 0-.88.586-1.498.587-.618 1.51-.618t1.51.618.586 1.499q0 .902-.586 1.489-.587.585-1.51.586m-6.244 3.487q-.548 0-.943-.395a1.29 1.29 0 0 1-.396-.944V6.757q0-.548.396-.944.395-.396.943-.396h2.42l1.5-2h4.648l1.5 2h2.42q.569 0 .954.396.385.395.385.944v8.487q0 .548-.385.944a1.28 1.28 0 0 1-.954.395z',
  'ic_cancel':
    'M7.063 13.712 10 10.774l2.938 2.938.773-.774L10.775 10l2.938-2.937-.774-.774L10 9.226 7.063 6.289l-.775.774L9.226 10l-2.938 2.938zM10 17.583q-1.559 0-2.941-.592a7.65 7.65 0 0 1-2.42-1.63 7.6 7.6 0 0 1-1.631-2.418 7.4 7.4 0 0 1-.592-2.941q0-1.58.592-2.953a7.641 7.641 0 0 1 4.049-4.04 7.4 7.4 0 0 1 2.94-.592q1.58 0 2.953.592a7.68 7.68 0 0 1 4.04 4.038q.592 1.372.592 2.952 0 1.56-.592 2.941a7.65 7.65 0 0 1-4.038 4.051 7.4 7.4 0 0 1-2.952.592',
  'ic_check':
    'm8.104 14.149-3.737-3.716.766-.787 2.971 2.971 6.763-6.742.766.766z',
  'ic_check_circle':
    'm8.921 12.712 4.686-4.665-.774-.774-3.912 3.89-1.77-1.75-.774.775zm1.08 4.871q-1.559 0-2.941-.592a7.65 7.65 0 0 1-2.42-1.63 7.6 7.6 0 0 1-1.631-2.418 7.4 7.4 0 0 1-.592-2.941q0-1.58.592-2.953a7.641 7.641 0 0 1 4.049-4.04 7.4 7.4 0 0 1 2.94-.592q1.58 0 2.953.592a7.68 7.68 0 0 1 4.04 4.038q.592 1.372.592 2.952 0 1.56-.592 2.941a7.65 7.65 0 0 1-4.038 4.051 7.4 7.4 0 0 1-2.952.592',
  'ic_chevron_down':
    'M10 12.782 5.288 8.07l.774-.774L10 11.234l3.938-3.938.773.774z',
  'ic_chevron_left':
    'M12 14.712 7.288 10 12 5.29l.774.774L8.837 10l3.937 3.938z',
  'ic_chevron_right':
    'M11.164 10 7.226 6.063 8 5.289 12.711 10 8 14.712l-.774-.774z',
  'ic_chevron_up':
    'm6.063 12.72-.775-.775L10 7.234l4.711 4.711-.774.774L10 8.782z',
  'ic_close':
    'm6.063 14.712-.775-.774L9.226 10 5.288 6.063l.774-.774L10 9.226l3.938-3.937.773.774L10.775 10l3.938 3.938-.774.774L10 10.774z',
  'ic_content_copy':
    'M7.54813 14.5834C7.17299 14.5834 6.8559 14.4538 6.59687 14.1948C6.33785 13.9358 6.20833 13.6187 6.20833 13.2436V3.75648C6.20833 3.38134 6.33785 3.06426 6.59687 2.80523C6.8559 2.5462 7.17299 2.41669 7.54813 2.41669H15.0352C15.4103 2.41669 15.7274 2.5462 15.9865 2.80523C16.2455 3.06426 16.375 3.38134 16.375 3.75648V13.2436C16.375 13.6187 16.2455 13.9358 15.9865 14.1948C15.7274 14.4538 15.4103 14.5834 15.0352 14.5834H7.54813ZM7.54813 13.5H15.0352C15.0994 13.5 15.1581 13.4733 15.2115 13.4198C15.2649 13.3665 15.2917 13.3077 15.2917 13.2436V3.75648C15.2917 3.69231 15.2649 3.63356 15.2115 3.58023C15.1581 3.52676 15.0994 3.50002 15.0352 3.50002H7.54813C7.48396 3.50002 7.42521 3.52676 7.37188 3.58023C7.3184 3.63356 7.29167 3.69231 7.29167 3.75648V13.2436C7.29167 13.3077 7.3184 13.3665 7.37188 13.4198C7.42521 13.4733 7.48396 13.5 7.54813 13.5ZM4.96479 17.1667C4.58965 17.1667 4.27257 17.0372 4.01354 16.7781C3.75451 16.5191 3.625 16.202 3.625 15.8269V5.25648H4.70833V15.8269C4.70833 15.8911 4.73507 15.9498 4.78854 16.0031C4.84188 16.0566 4.90062 16.0834 4.96479 16.0834H13.5352V17.1667H4.96479Z',
  'ic_credit_card':
    'M3.756 15.583q-.548 0-.943-.396a1.29 1.29 0 0 1-.396-.943V5.757q0-.548.396-.944.395-.396.943-.396h12.488q.569 0 .954.396.385.395.385.944v8.487q0 .548-.385.944a1.28 1.28 0 0 1-.954.395zM3.5 9.663h13V7.338h-13z',
  'ic_delegatecash':
    'M8.274 17.2a10 10 0 0 1-1.281-1.142c.643-1.145 1.454-2.272 2.294-3.126.526-.534 1.377-1.294 2.342-2.155 1.86-1.66 4.142-3.697 5.335-5.23q.066.233.066.521c0 3.415-1.288 6.442-2.88 8.62-.794 1.087-1.649 1.94-2.424 2.511-.808.596-1.412.801-1.726.801s-.918-.205-1.726-.8m8.562-13.232-6.538-2.904a.73.73 0 0 0-.596 0L3.164 3.968C2.279 4.362 2 5.308 2 6.068c0 4.681 2.246 8.647 4.514 10.908C7.806 18.265 9.105 19 10 19c2.466 0 8-5.585 8-12.932 0-.476-.11-1.025-.409-1.466l-.044-.062a1.77 1.77 0 0 0-.711-.572',
  'ic_delete':
    'M6.756 16.583q-.563 0-.95-.388a1.3 1.3 0 0 1-.39-.951V5.5h-1V4.417H8v-.904h4v.904h3.583V5.5h-1v9.735q0 .577-.388.963a1.3 1.3 0 0 1-.951.385zM8.336 14H9.42V7H8.337zm2.244 0h1.083V7H10.58z',
  'ic_discord':
    'M15.538 4.998A13 13 0 0 0 12.285 4a.07.07 0 0 0-.054.023c-.137.247-.297.57-.404.817a12.5 12.5 0 0 0-3.657 0 8 8 0 0 0-.411-.817C7.75 4.008 7.729 4 7.705 4a13.2 13.2 0 0 0-3.253.998q-.011.002-.023.015c-2.072 3.051-2.644 6.02-2.362 8.96q0 .024.023.037a13.3 13.3 0 0 0 3.993 1.987c.022.008.045 0 .053-.015q.46-.618.815-1.304c.015-.03 0-.06-.03-.068a9.5 9.5 0 0 1-1.25-.585c-.03-.015-.03-.06-.008-.082.084-.06.168-.128.252-.188a.05.05 0 0 1 .053-.007c2.621 1.177 5.448 1.177 8.039 0a.05.05 0 0 1 .053.007c.084.068.167.128.251.195.03.023.03.068-.007.083-.396.232-.816.42-1.25.585-.03.007-.038.044-.03.067q.368.685.815 1.305c.023.007.046.015.069.007a13.3 13.3 0 0 0 4-1.987.04.04 0 0 0 .022-.037c.336-3.397-.556-6.344-2.361-8.96q-.01-.013-.031-.015m-8.19 7.183c-.785 0-1.44-.713-1.44-1.59s.64-1.59 1.44-1.59c.807 0 1.447.72 1.44 1.59 0 .877-.64 1.59-1.44 1.59m5.31 0c-.785 0-1.44-.713-1.44-1.59s.64-1.59 1.44-1.59c.808 0 1.448.72 1.44 1.59 0 .877-.632 1.59-1.44 1.59',
  'ic_edit':
    'M3.417 16.583v-2.466L13.784 3.734a1 1 0 0 1 .365-.244q.198-.073.406-.073.21 0 .402.068.194.068.375.241l.934.926q.173.182.245.375a1.16 1.16 0 0 1-.003.816q-.075.197-.242.365L5.883 16.583zm10.98-10.046L15.5 5.442l-.942-.942-1.095 1.103z',
  'ic_ellipsis':
    'M5.688 11.083q-.45 0-.764-.32a1.06 1.06 0 0 1-.315-.767q0-.45.32-.764.32-.315.768-.315t.764.32q.315.318.315.768 0 .448-.32.763t-.768.315m4.308 0q-.45 0-.764-.32a1.06 1.06 0 0 1-.315-.767q0-.45.32-.764.318-.315.767-.315.45 0 .764.32.315.318.315.768 0 .448-.32.763-.318.315-.767.315m4.307 0q-.449 0-.764-.32a1.06 1.06 0 0 1-.315-.767q0-.45.32-.764.32-.315.768-.315.45 0 .764.32.315.318.315.768 0 .448-.32.763t-.768.315',
  'ic_error':
    'M9.996 13.776a.58.58 0 0 0 .594-.586.58.58 0 0 0-.586-.594.58.58 0 0 0-.594.586.58.58 0 0 0 .586.594m-.538-2.712h1.084v-5H9.458zm.55 6.52a7.4 7.4 0 0 1-2.948-.593 7.65 7.65 0 0 1-2.42-1.63 7.6 7.6 0 0 1-1.631-2.418 7.4 7.4 0 0 1-.592-2.951q0-1.57.592-2.943a7.644 7.644 0 0 1 4.048-4.04 7.4 7.4 0 0 1 2.951-.592q1.57 0 2.943.592a7.68 7.68 0 0 1 4.04 4.043 7.4 7.4 0 0 1 .592 2.94 7.4 7.4 0 0 1-.592 2.948 7.65 7.65 0 0 1-1.63 2.42 7.7 7.7 0 0 1-2.413 1.631 7.4 7.4 0 0 1-2.94.592',
  'ic_expand':
    'M5.417 14.583V10H6.5v3.5H10v1.083zM13.5 10V6.5H10V5.417h4.583V10z',
  'ic_facebook':
    'M8.25 7.667H6.5V10h1.75v7h2.917v-7h2.124l.209-2.333h-2.333v-.973c0-.557.112-.777.65-.777H13.5V3h-2.221C9.18 3 8.25 3.923 8.25 5.692z',
  'ic_forum':
    'M7.596 14.263q-.584 0-.994-.41a1.35 1.35 0 0 1-.41-.994v-.583h8.98l.264.264V6h.583q.585 0 .994.41t.41.994v9.474l-2.615-2.615zm-5.019-.872v-9.57q0-.585.41-.994t.994-.41h8.551q.584 0 .994.41t.41.994v5.55q0 .585-.41.995t-.994.41h-7.34z',
  'ic_heart_filled':
    'm10 16.44-.753-.682a115 115 0 0 1-3.386-3.137q-1.333-1.3-2.095-2.29-.761-.99-1.055-1.797a4.8 4.8 0 0 1-.294-1.638q0-1.67 1.154-2.825 1.155-1.154 2.825-1.154 1.029 0 1.979.49A4.7 4.7 0 0 1 10 4.806a4.7 4.7 0 0 1 1.625-1.4 4.3 4.3 0 0 1 1.98-.49q1.668 0 2.824 1.154 1.154 1.155 1.154 2.825 0 .831-.283 1.617-.285.785-1.045 1.766-.761.98-2.102 2.3t-3.442 3.22z',
  'ic_heart_outline':
    'm10 16.44-.753-.682a115 115 0 0 1-3.386-3.137q-1.333-1.3-2.095-2.29-.761-.99-1.055-1.797a4.8 4.8 0 0 1-.294-1.638q0-1.67 1.154-2.825 1.155-1.154 2.825-1.154 1.029 0 1.979.49A4.7 4.7 0 0 1 10 4.806a4.7 4.7 0 0 1 1.625-1.4 4.3 4.3 0 0 1 1.98-.49q1.668 0 2.824 1.154 1.154 1.155 1.154 2.825 0 .831-.283 1.617-.285.785-1.045 1.766-.761.98-2.102 2.3t-3.442 3.22zm0-1.46a103 103 0 0 0 3.188-2.96q1.25-1.223 1.99-2.13.739-.905 1.03-1.61t.292-1.384q0-1.23-.833-2.063T13.604 4q-.737 0-1.376.31-.64.31-1.274.996l-.73.854h-.448l-.73-.854q-.642-.694-1.298-1A3.2 3.2 0 0 0 6.396 4q-1.22 0-2.059.833-.837.834-.837 2.063 0 .68.27 1.352.271.674.99 1.57.72.895 1.98 2.124T10 14.98',
  'ic_image':
    'M4.756 16.583q-.569 0-.954-.395a1.3 1.3 0 0 1-.385-.944V4.757q0-.548.385-.944t.954-.396h10.488q.568 0 .954.396.385.395.385.943v10.488q0 .548-.385.944a1.28 1.28 0 0 1-.954.395zm1.369-2.791h7.814l-2.615-3.487-2.09 2.711-1.292-1.631z',
  'ic_info':
    'M9.458 13.792h1.084V9H9.458zm.538-6.052a.58.58 0 0 0 .594-.585.58.58 0 0 0-.586-.594.58.58 0 0 0-.594.585.58.58 0 0 0 .586.594m.011 9.843a7.4 7.4 0 0 1-2.947-.592 7.65 7.65 0 0 1-2.42-1.63 7.6 7.6 0 0 1-1.631-2.418 7.4 7.4 0 0 1-.592-2.951q0-1.57.592-2.943a7.644 7.644 0 0 1 4.048-4.04 7.4 7.4 0 0 1 2.951-.592q1.57 0 2.943.592a7.68 7.68 0 0 1 4.04 4.043 7.4 7.4 0 0 1 .592 2.94 7.4 7.4 0 0 1-.592 2.948 7.65 7.65 0 0 1-1.63 2.42 7.7 7.7 0 0 1-2.413 1.631 7.4 7.4 0 0 1-2.94.592',
  'ic_instagram':
    'M12.917 4.167c.773 0 1.505.306 2.06.856a2.9 2.9 0 0 1 .856 2.06v5.834c0 .773-.306 1.505-.856 2.06a2.9 2.9 0 0 1-2.06.856H7.083a2.9 2.9 0 0 1-2.06-.856 2.9 2.9 0 0 1-.856-2.06V7.083c0-.773.306-1.505.856-2.06a2.9 2.9 0 0 1 2.06-.856zm0-1.167H7.083A4.095 4.095 0 0 0 3 7.083v5.834A4.095 4.095 0 0 0 7.083 17h5.834A4.095 4.095 0 0 0 17 12.917V7.083A4.095 4.095 0 0 0 12.917 3m.875 4.083a.873.873 0 0 1-.875-.875.875.875 0 1 1 .875.875M12.333 10A2.335 2.335 0 0 0 10 7.667 2.335 2.335 0 0 0 7.667 10 2.335 2.335 0 0 0 10 12.333 2.335 2.335 0 0 0 12.333 10M6.5 10a3.5 3.5 0 0 1 7 0 3.5 3.5 0 0 1-7 0',
  'ic_key':
    'M6 12.02q.841 0 1.43-.59.59-.589.59-1.43 0-.842-.59-1.43A1.95 1.95 0 0 0 6 7.98q-.841 0-1.43.59-.59.588-.59 1.43 0 .841.59 1.43.589.59 1.43.59m0 2.451q-1.875 0-3.173-1.298T1.529 10t1.298-3.173T6 5.529q1.489 0 2.679.888a4.33 4.33 0 0 1 1.606 2.291h7.03l1.3 1.3-2.325 2.744-1.47-1.565-1.427 1.268-1.316-1.163h-1.792q-.387 1.37-1.574 2.274A4.35 4.35 0 0 1 6 14.471',
  'ic_language':
    'M10 17.583a7.4 7.4 0 0 1-2.948-.593 7.6 7.6 0 0 1-2.416-1.626 7.6 7.6 0 0 1-1.626-2.416A7.4 7.4 0 0 1 2.417 10q0-1.578.593-2.954a7.7 7.7 0 0 1 1.626-2.41A7.6 7.6 0 0 1 7.052 3.01 7.4 7.4 0 0 1 10 2.417q1.578 0 2.954.593a7.7 7.7 0 0 1 2.41 1.626 7.7 7.7 0 0 1 1.626 2.41q.594 1.376.593 2.954a7.4 7.4 0 0 1-.593 2.948 7.6 7.6 0 0 1-1.626 2.416 7.7 7.7 0 0 1-2.41 1.626 7.4 7.4 0 0 1-2.954.593m0-1.121q.45-.45.845-1.468.393-1.017.605-2.266h-2.9q.227 1.28.613 2.299.387 1.016.837 1.435m-1.343-.105q-.396-.624-.715-1.573a12.3 12.3 0 0 1-.488-2.056H4.106a6.4 6.4 0 0 0 1.864 2.4 6 6 0 0 0 2.687 1.23m2.686 0a6 6 0 0 0 2.687-1.23 6.4 6.4 0 0 0 1.864-2.4h-3.347q-.21 1.116-.53 2.065-.318.948-.674 1.565m-7.615-4.713h3.618a9 9 0 0 1-.072-.87 24 24 0 0 1 0-1.611q.015-.405.072-.807H3.728q-.12.404-.174.82a6.4 6.4 0 0 0 0 1.648q.053.417.174.82m4.722 0h3.1q.057-.465.072-.861a22 22 0 0 0 0-1.576 9 9 0 0 0-.072-.851h-3.1q-.057.444-.072.85a22 22 0 0 0 0 1.587q.015.407.072.851m4.204 0h3.618q.12-.403.174-.82a6.4 6.4 0 0 0 0-1.658 4.6 4.6 0 0 0-.174-.81h-3.618q.057.444.072.87a24 24 0 0 1 0 1.611q-.015.406-.072.807m-.107-4.371h3.347q-.668-1.461-1.852-2.4a6.2 6.2 0 0 0-2.7-1.238q.397.665.708 1.601.31.938.496 2.037m-3.997 0h2.9q-.227-1.275-.625-2.311-.398-1.038-.825-1.423-.426.385-.825 1.423a12.7 12.7 0 0 0-.625 2.31m-4.444 0h3.348q.186-1.1.496-2.037a8.2 8.2 0 0 1 .707-1.601 6.15 6.15 0 0 0-2.703 1.242 6.5 6.5 0 0 0-1.848 2.396',
  'ic_link':
    'M8.84 13.616H6.032q-1.5 0-2.558-1.06a3.5 3.5 0 0 1-1.057-2.56q0-1.503 1.057-2.557a3.5 3.5 0 0 1 2.558-1.054H8.84v1.083H6.032q-1.05 0-1.79.741A2.44 2.44 0 0 0 3.5 10q0 1.05.741 1.791a2.44 2.44 0 0 0 1.791.741H8.84zm-1.632-3.074V9.458h5.584v1.084zm3.952 3.074v-1.084h2.808q1.05 0 1.79-.741a2.44 2.44 0 0 0 .742-1.79q0-1.05-.741-1.792a2.44 2.44 0 0 0-1.791-.74H11.16V6.384h2.808q1.5 0 2.558 1.058a3.5 3.5 0 0 1 1.057 2.561q0 1.503-1.057 2.557a3.5 3.5 0 0 1-2.558 1.055z',
  'ic_linkedin':
    'M14.083 3H5.917A2.917 2.917 0 0 0 3 5.917v8.166A2.917 2.917 0 0 0 5.917 17h8.166A2.917 2.917 0 0 0 17 14.083V5.917A2.917 2.917 0 0 0 14.083 3M7.667 14.083h-1.75V7.667h1.75zm-.875-7.156c-.564 0-1.021-.46-1.021-1.029 0-.568.457-1.029 1.02-1.029.564 0 1.021.46 1.021 1.029 0 .568-.456 1.029-1.02 1.029m7.875 7.156h-1.75v-3.269c0-1.964-2.334-1.816-2.334 0v3.27h-1.75V7.666h1.75v1.03c.815-1.51 4.084-1.62 4.084 1.444z',
  'ic_live': 'M10 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8',
  'ic_location':
    'M10.006 9.888q.555 0 .944-.395.39-.395.39-.95 0-.556-.396-.945-.395-.39-.95-.39t-.944.396-.39.95.396.944q.395.39.95.39M10 17.59q-3.062-2.602-4.593-4.845-1.53-2.242-1.53-4.143 0-2.551 1.71-4.369T9.99 2.417q2.692 0 4.413 1.817 1.72 1.818 1.72 4.369 0 1.9-1.52 4.133-1.52 2.235-4.603 4.855',
  'ic_lock':
    'M5.75 17.583a1.28 1.28 0 0 1-.943-.393 1.3 1.3 0 0 1-.39-.946V8.757q0-.553.393-.947a1.3 1.3 0 0 1 .946-.393h.66v-2q0-1.492 1.048-2.538 1.047-1.045 2.54-1.045 1.495 0 2.537 1.045 1.042 1.046 1.042 2.538v2h.66q.553 0 .947.393.393.394.393.947v7.487q0 .552-.393.946a1.3 1.3 0 0 1-.947.393zm4.254-3.791q.54 0 .914-.378.375-.378.374-.918 0-.54-.378-.914a1.26 1.26 0 0 0-.918-.373q-.54 0-.914.377a1.26 1.26 0 0 0-.374.918q0 .54.378.914.378.375.918.374M7.5 7.417h5v-2q0-1.042-.73-1.771a2.4 2.4 0 0 0-1.77-.73 2.4 2.4 0 0 0-1.77.73 2.4 2.4 0 0 0-.73 1.77z',
  'ic_mail':
    'M3.756 15.583q-.548 0-.943-.396a1.29 1.29 0 0 1-.396-.943V5.757q0-.548.396-.944.395-.396.943-.396h12.488q.569 0 .954.396.385.395.385.944v8.487q0 .548-.385.944a1.28 1.28 0 0 1-.954.395zM10 10.631l6.5-3.825-.128-1.306L10 9.23 3.628 5.5 3.5 6.806z',
  'ic_menu':
    'M3.417 14.195v-1.083h13.166v1.083zm0-3.653V9.458h13.166v1.084zm0-3.654V5.804h13.166v1.084z',
  'ic_minus': 'M5.25 10.542V9.458h9.5v1.083z',
  'ic_notification_active':
    'M4.417 15.404V14.32h.84V8.936q0-1.764 1.11-3.087t2.841-1.6v-1.04q0-.33.232-.56a.76.76 0 0 1 .56-.232q.329 0 .56.231a.76.76 0 0 1 .232.56v1.04q1.73.278 2.841 1.6 1.11 1.324 1.11 3.088v5.385h.84v1.083zM10 17.744q-.56 0-.95-.39t-.39-.95h2.68q0 .56-.39.95t-.95.39M2.513 8.928q0-1.872.83-3.445a7.6 7.6 0 0 1 2.232-2.605l.648.88a6.6 6.6 0 0 0-1.906 2.224 6.13 6.13 0 0 0-.72 2.946zm13.89 0q0-1.601-.72-2.946a6.6 6.6 0 0 0-1.906-2.224l.648-.88a7.6 7.6 0 0 1 2.233 2.605q.83 1.573.83 3.445z',
  'ic_notification_filled':
    'M4.417 15.404V14.32h.84V8.936q0-1.764 1.11-3.087t2.841-1.6v-1.04q0-.33.231-.561a.76.76 0 0 1 .56-.231q.33 0 .561.23.232.233.232.561v1.04q1.73.278 2.841 1.6 1.11 1.324 1.11 3.088v5.385h.84v1.083zm5.577 2.34a1.28 1.28 0 0 1-.944-.394 1.3 1.3 0 0 1-.39-.946h2.68q0 .56-.396.95-.395.39-.95.39',
  'ic_notification_outline':
    'M4.417 15.404V14.32h.84V8.936q0-1.764 1.11-3.087t2.841-1.6v-1.04q0-.33.231-.561a.76.76 0 0 1 .56-.231q.33 0 .561.23.232.233.232.561v1.04q1.73.278 2.841 1.6 1.11 1.324 1.11 3.088v5.385h.84v1.083zm5.577 2.34a1.28 1.28 0 0 1-.944-.394 1.3 1.3 0 0 1-.39-.946h2.68q0 .56-.396.95-.395.39-.95.39M6.34 14.32h7.32V8.936q0-1.53-1.065-2.595T10 5.276 7.405 6.34 6.34 8.936z',
  'ic_pause': 'M11.75 14.833V5.167h3.292v9.666zm-6.792 0V5.167H8.25v9.666z',
  'ic_pdf':
    'M7.356 10.452h.904v-1.5h.596q.384 0 .644-.26a.88.88 0 0 0 .26-.644v-.596a.88.88 0 0 0-.26-.644.88.88 0 0 0-.644-.26h-1.5zm.904-2.404v-.596h.596v.596zm2.016 2.404h1.468q.384 0 .644-.26a.88.88 0 0 0 .26-.644V7.452a.88.88 0 0 0-.26-.644.88.88 0 0 0-.644-.26h-1.468zm.904-.904V7.452h.564v2.096zm2.064.904h.903v-1.5h1.16v-.904h-1.16v-.596h1.16v-.904h-2.063zm-6.696 4.131q-.562 0-.951-.388a1.3 1.3 0 0 1-.389-.951V3.757q0-.564.389-.952t.951-.388h9.487q.563 0 .951.388.39.39.389.951v9.488q0 .563-.389.95-.389.39-.95.39zm-2.582 2.584q-.564 0-.952-.389a1.3 1.3 0 0 1-.389-.952V5.256h1.083v10.57q0 .097.08.177.081.08.177.08h10.57v1.084z',
  'ic_pin':
    'M12.583 10.176 14.407 12v1.083h-3.865v4.334l-.542.541-.542-.541v-4.334H5.593V12l1.824-1.824V4.5h-1V3.417h7.166V4.5h-1z',
  'ic_play': 'M7.417 14.747V5.253L14.73 10z',
  'ic_public':
    'M10.007 17.583a7.4 7.4 0 0 1-2.947-.592 7.65 7.65 0 0 1-2.42-1.63 7.6 7.6 0 0 1-1.631-2.418 7.4 7.4 0 0 1-.592-2.951q0-1.57.592-2.943a7.644 7.644 0 0 1 4.048-4.04 7.4 7.4 0 0 1 2.951-.592q1.57 0 2.943.592a7.68 7.68 0 0 1 4.04 4.043 7.4 7.4 0 0 1 .592 2.94 7.4 7.4 0 0 1-.592 2.948 7.65 7.65 0 0 1-1.63 2.42 7.7 7.7 0 0 1-2.413 1.631 7.4 7.4 0 0 1-2.94.592M9 16.417V15a.96.96 0 0 1-.708-.294A.97.97 0 0 1 8 14v-1L3.646 8.646q-.063.333-.104.667A6 6 0 0 0 3.5 10q0 2.46 1.583 4.26Q6.667 16.063 9 16.418m6-2.271q-1.187 1.188-1.094.948.094-.24.664-1.137t1.25-2.073T16.5 10q0-2.04-1.11-3.663a6.36 6.36 0 0 0-2.89-2.353V4.5q0 .62-.44 1.06Q11.617 6 11 6H9v1q0 .424-.287.713A.97.97 0 0 1 8 8H7v2h5q.424 0 .713.288.287.287.287.712v2h.854q.48 0 .813.333.333.334.333.813',
  'ic_receipt':
    'M5 17.583q-.79 0-1.187-.386-.396-.386-.396-1.193v-2.587h2V2.577l1.104.952 1.12-.952 1.12.952 1.12-.952L11 3.529l1.12-.952 1.12.952 1.12-.952 1.12.952 1.103-.952V15.75q0 .81-.521 1.322-.522.51-1.312.511zm9.75-1.083a.75.75 0 0 0 .542-.198q.208-.198.208-.552V4.5h-9v8.917H14v2.333q.021.354.219.552t.531.198M7.66 7.292V6.208h4.228v1.084zm0 2.5V8.708h4.228v1.084zm6.042-2.388a.65.65 0 0 1-.449-.19.6.6 0 0 1-.205-.46q0-.268.205-.463a.65.65 0 0 1 .46-.195q.253 0 .448.19t.195.46-.192.463a.63.63 0 0 1-.462.195m0 2.5a.65.65 0 0 1-.449-.19.6.6 0 0 1-.205-.46q0-.268.205-.463a.65.65 0 0 1 .46-.195q.253 0 .448.19t.195.46-.192.463a.63.63 0 0 1-.462.195',
  'ic_refresh':
    'M10.032 15.583q-2.325 0-3.954-1.628-1.63-1.629-1.63-3.954t1.63-3.954q1.629-1.63 3.954-1.63 1.378 0 2.527.618a5.8 5.8 0 0 1 1.909 1.637V4.417h1.083V8.68H11.29V7.596h2.52a4.46 4.46 0 0 0-1.587-1.526q-.99-.57-2.19-.57-1.875 0-3.187 1.313Q5.532 8.125 5.532 10t1.313 3.188 3.187 1.312q1.75 0 3-1.156A4.42 4.42 0 0 0 14.47 10.5h1.108q-.171 2.165-1.755 3.624-1.585 1.46-3.79 1.46',
  'ic_schedule':
    'm12.936 13.398.774-.774-3.168-3.169V5H9.458v4.92zM10 17.583a7.4 7.4 0 0 1-2.945-.592 7.643 7.643 0 0 1-4.047-4.048 7.4 7.4 0 0 1-.592-2.951q0-1.57.592-2.953a7.5 7.5 0 0 1 1.63-2.41 7.7 7.7 0 0 1 2.418-1.62 7.4 7.4 0 0 1 2.951-.592q1.571 0 2.953.597a7.7 7.7 0 0 1 2.406 1.62 7.7 7.7 0 0 1 1.62 2.408q.596 1.383.596 2.957a7.4 7.4 0 0 1-.592 2.945 7.8 7.8 0 0 1-1.619 2.417 7.51 7.51 0 0 1-5.37 2.222',
  'ic_search':
    'm15.89 16.647-4.964-4.963a5 5 0 0 1-1.37.735q-.746.26-1.54.26-1.949 0-3.314-1.364T3.337 8 4.7 4.687 8.015 3.32q1.948 0 3.314 1.365T12.695 8a4.6 4.6 0 0 1-.268 1.563 5 5 0 0 1-.727 1.346l4.963 4.963zm-7.874-5.05q1.506-.001 2.551-1.046T11.612 8t-1.045-2.551Q9.523 4.404 8.016 4.404T5.465 5.449 4.42 8t1.045 2.551q1.044 1.045 2.551 1.045',
  'ic_share':
    'M5.75 18.583q-.562 0-.948-.388a1.3 1.3 0 0 1-.385-.951V8.756q0-.563.388-.95.39-.39.951-.39h2.132V8.5H5.756a.25.25 0 0 0-.176.08.25.25 0 0 0-.08.176v8.488q0 .096.08.176t.176.08h8.488a.25.25 0 0 0 .176-.08.25.25 0 0 0 .08-.177V8.757a.25.25 0 0 0-.08-.176.25.25 0 0 0-.176-.08h-2.132V7.417h2.132q.568 0 .954.388a1.3 1.3 0 0 1 .385.951v8.488q0 .563-.389.95-.388.39-.951.39zm3.708-5.791V3.786L8.062 5.183l-.774-.766L10 1.705l2.711 2.712-.774.766-1.395-1.396v9.005z',
  'ic_shipping':
    'M4.87 15.631q-.976 0-1.657-.682a2.26 2.26 0 0 1-.68-1.657H1.287V5.257q0-.564.389-.952t.951-.388h11.18v2.84h2.211l2.693 3.772v2.763h-1.34q0 .975-.683 1.657a2.26 2.26 0 0 1-1.659.682q-.975 0-1.657-.682a2.26 2.26 0 0 1-.68-1.657H7.211q0 .977-.683 1.658a2.26 2.26 0 0 1-1.66.681m.002-1.083q.529 0 .893-.364.363-.363.363-.892t-.363-.893a1.21 1.21 0 0 0-.893-.364q-.529 0-.893.364-.363.363-.364.893 0 .529.364.893.363.363.893.363m10.16 0q.529 0 .893-.364.363-.363.363-.892t-.363-.893a1.21 1.21 0 0 0-.893-.364q-.529 0-.893.364-.363.363-.363.893 0 .529.363.893.364.363.893.363m-1.224-3.84H17.5L15.44 7.84h-1.632z',
  'ic_smile':
    'M12.672 9.09q.455 0 .771-.319.317-.318.317-.773t-.319-.771a1.06 1.06 0 0 0-.773-.317q-.454 0-.771.319-.317.318-.317.773t.319.771.773.317m-5.34 0q.454 0 .771-.319.317-.318.317-.773T8.1 7.227a1.06 1.06 0 0 0-.773-.317q-.455 0-.771.319-.317.318-.317.773t.319.771.773.317M10 13.744q1.18 0 2.197-.532a3.2 3.2 0 0 0 1.489-1.608H6.314q.47 1.076 1.489 1.608 1.017.531 2.197.532m.007 3.84a7.4 7.4 0 0 1-2.947-.593 7.65 7.65 0 0 1-2.42-1.63 7.6 7.6 0 0 1-1.631-2.418 7.4 7.4 0 0 1-.592-2.951q0-1.57.592-2.943a7.644 7.644 0 0 1 4.048-4.04 7.4 7.4 0 0 1 2.951-.592q1.57 0 2.943.592a7.68 7.68 0 0 1 4.04 4.043 7.4 7.4 0 0 1 .592 2.94 7.4 7.4 0 0 1-.592 2.948 7.65 7.65 0 0 1-1.63 2.42 7.7 7.7 0 0 1-2.413 1.631 7.4 7.4 0 0 1-2.94.592',
  'ic_sort':
    'M3.417 14.083V13h4.479v1.083zm0-3.541V9.458h8.319v1.084zm0-3.542V5.917h13.166V7z',
  'ic_spinner':
    'M10 2.417a7.4 7.4 0 0 0-2.943.592 7.64 7.64 0 0 0-4.048 4.04 7.35 7.35 0 0 0-.592 2.943q0 1.57.592 2.951t1.63 2.419a7.65 7.65 0 0 0 2.42 1.63 7.4 7.4 0 0 0 2.948.591 7.4 7.4 0 0 0 2.941-.592 7.7 7.7 0 0 0 2.414-1.63 7.65 7.65 0 0 0 1.63-2.42q.59-1.38.591-2.941H16.5q0 2.709-1.896 4.604T10 16.5t-4.604-1.896T3.5 10q0-2.709 1.896-4.604T10 3.5z',
  'ic_star':
    'm5.688 16.134 1.152-4.828-3.822-3.248 5.012-.436L10 3.066l1.97 4.577 5.012.415-3.822 3.248 1.152 4.828L10 13.574z',
  'ic_store':
    'M4.176 5.292V4.208h11.648v1.084zm.032 11v-4.5H3.112v-1.084l1.064-4h11.648l1.064 4v1.083h-1.096v4.5h-1.084v-4.5h-3.916v4.5zm1.084-1.084h4.416v-3.417H5.292z',
  'ic_ticket':
    'M9.996 13.212a.534.534 0 0 0 .546-.538.534.534 0 0 0-.538-.546.534.534 0 0 0-.546.538.534.534 0 0 0 .538.545m0-2.67a.534.534 0 0 0 .546-.538.534.534 0 0 0-.538-.546.534.534 0 0 0-.546.538.534.534 0 0 0 .538.546m0-2.67a.534.534 0 0 0 .546-.538.534.534 0 0 0-.538-.546.534.534 0 0 0-.546.538.534.534 0 0 0 .538.546m6.248 7.711H3.757a1.3 1.3 0 0 1-.947-.393 1.3 1.3 0 0 1-.393-.946v-2.407q.642-.137 1.071-.65.429-.515.429-1.191a1.8 1.8 0 0 0-.429-1.19 1.87 1.87 0 0 0-1.071-.649V5.75q0-.553.393-.943.394-.39.946-.39h12.488q.552 0 .946.393.393.394.393.946v2.407q-.642.137-1.071.65-.429.514-.429 1.191t.429 1.19 1.071.649v2.407q0 .553-.393.943-.394.39-.946.39',
  'ic_tune':
    'M9.708 16.792v-4.584h1.084v1.75h6v1.083h-6v1.75zm-6.5-1.75v-1.084h4.584v1.083zm3-2.75v-1.75h-3V9.458h3v-1.75h1.084v4.583zm3-1.75V9.458h7.584v1.083zm3-2.75V3.208h1.084v1.75h3.5v1.084h-3.5v1.75zm-9-1.75V4.958h7.584v1.084z',
  'ic_twitter':
    'M13.944 4h2.103l-4.595 5.083L16.857 16h-4.232l-3.314-4.195L5.518 16H3.414l4.914-5.437L3.143 4h4.34l2.995 3.834zm-.739 10.782h1.166L6.849 5.154h-1.25z',
  'ic_verified':
    'M7.405 17.971 5.94 15.548l-2.761-.619.255-2.823L1.56 10l1.872-2.106-.255-2.824 2.761-.618 1.466-2.423L10 3.136l2.595-1.107 1.466 2.423 2.761.618-.255 2.824L18.44 10l-1.872 2.106.255 2.823-2.761.619-1.466 2.423L10 16.864zm1.532-5.26 4.67-4.649-.774-.758-3.895 3.875-1.771-1.766-.774.774z',
  'ic_wallet':
    'M4.756 16.583q-.563 0-.95-.388a1.3 1.3 0 0 1-.39-.951V4.757q0-.564.39-.952.387-.388.95-.388h10.488q.563 0 .95.388.39.39.39.951v.577h-5.828q-1.01 0-1.716.707a2.34 2.34 0 0 0-.707 1.717v4.487q0 1.01.707 1.716a2.34 2.34 0 0 0 1.716.707h5.827v.577q0 .568-.388.954a1.3 1.3 0 0 1-.951.385zm6-3q-.563 0-.95-.388a1.3 1.3 0 0 1-.39-.951V7.757q0-.564.39-.952.387-.388.95-.388h5.488q.563 0 .95.388.39.389.39.952v4.487q0 .563-.39.95-.387.39-.95.39zM13.5 11.25q.52 0 .885-.364.366-.366.365-.886 0-.52-.365-.885a1.2 1.2 0 0 0-.885-.365q-.52 0-.885.365a1.2 1.2 0 0 0-.365.885q0 .52.365.886.364.364.885.364',
  'ic_whatsapp':
    'M10.127 3C6.33 3 3.254 6.053 3.254 9.82c0 1.288.36 2.493.986 3.521L3 17l3.805-1.209c.985.54 2.117.849 3.322.849 3.796 0 6.873-3.054 6.873-6.82S13.923 3 10.127 3m3.417 9.41c-.161.4-.892.766-1.215.783-.322.017-.332.25-2.089-.513-1.757-.764-2.814-2.62-2.897-2.74-.084-.12-.68-.97-.649-1.827.033-.856.502-1.26.67-1.429a.67.67 0 0 1 .475-.2c.139-.002.228-.004.33 0 .103.003.257-.022.39.332s.451 1.224.492 1.312.066.192.003.306-.095.185-.186.283c-.092.099-.193.22-.275.296-.091.083-.187.174-.09.352.095.178.426.76.93 1.242.648.617 1.206.822 1.378.916s.275.083.382-.03c.106-.112.457-.491.581-.66s.24-.138.398-.074 1.005.518 1.178.611c.172.094.287.142.328.216s.028.423-.134.824',
  'ic_youtube':
    'M17.896 6.649c0-1.407-1.035-2.538-2.313-2.538a113 113 0 0 0-5.302-.113H9.72c-1.801 0-3.57.031-5.302.113-1.275 0-2.31 1.137-2.31 2.544a46 46 0 0 0-.11 3.338 48 48 0 0 0 .107 3.342c0 1.406 1.034 2.547 2.31 2.547 1.82.085 3.685.122 5.583.12 1.9.005 3.76-.032 5.583-.12 1.278 0 2.313-1.14 2.313-2.547a48 48 0 0 0 .106-3.345 46 46 0 0 0-.103-3.341m-9.428 6.41V6.919l4.533 3.07z',
  'ic_carousel':
    'M1.32031 15.5834V4.41675H12.4868V15.5834H1.32031ZM14.4997 15.5834V4.41675H15.583V15.5834H14.4997ZM17.5959 15.5834V4.41675H18.6791V15.5834H17.5959Z',
  'ic_grid': 'M3 9V3H9V9H3ZM3 17V11H9V17H3ZM11 9V3H17V9H11ZM11 17V11H17V17H11Z',
  'ic_shopping':
    'M4.757 17.583q-.564 0-.952-.388a1.3 1.3 0 0 1-.388-.951V6.757q0-.564.388-.952.389-.388.952-.388h1.66q0-1.492 1.047-2.538t2.54-1.046q1.495 0 2.537 1.046t1.042 2.538h1.66q.564 0 .952.388.388.389.388.952v9.487q0 .563-.388.95-.39.39-.951.39zm5.248-6q1.493 0 2.536-1.046T13.583 8H12.5q0 1.042-.73 1.77a2.4 2.4 0 0 1-1.77.73 2.4 2.4 0 0 1-1.77-.73A2.4 2.4 0 0 1 7.5 8H6.417q0 1.499 1.047 2.54 1.047 1.043 2.54 1.043M7.5 5.417h5q0-1.042-.73-1.771a2.4 2.4 0 0 0-1.77-.73 2.4 2.4 0 0 0-1.77.73 2.4 2.4 0 0 0-.73 1.77',
} as const;

export type IconName = keyof typeof iconSet;

interface Props {
  name: IconName;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

export const Icon: React.FC<Props> = ({ className, name, size = 'sm' }) => {
  const sizeMapper = {
    xl: 'w-12 h-12',
    lg: 'w-9 h-9',
    md: 'w-6 h-6',
    sm: 'w-5 h-5',
    xs: 'w-4 h-4',
  };

  return (
    <span
      aria-hidden="true"
      className={classNames('block', sizeMapper[size], className)}
    >
      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d={iconSet[name]} fill="currentColor" />
      </svg>
    </span>
  );
};
