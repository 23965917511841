import classNames from 'classnames';

import { Image } from '../../../../components/Image';

export type ArtistListProps = {
  image: StoryblokImage;
  name: string;
  overline: string;
};

export const ArtistList: React.FC<ArtistListProps> = ({
  image,
  name,
  overline,
}) => {
  return (
    <div
      className={classNames(
        'flex w-full items-center justify-center gap-x-3 overflow-hidden',
        classNames,
      )}
    >
      <div className="w-16 shrink-0">
        <Image
          alt={image.alt}
          focus={image.focus}
          hoverEffect
          shape="rounded"
          src={image.filename}
          width={64}
        />
      </div>

      <div className="flex w-full flex-col overflow-hidden transition duration-300 ease-out group-hover:text-neutral-4">
        <p className="truncate font-bold">{name}</p>
        <p className="truncate">{overline}</p>
      </div>
    </div>
  );
};
