const aaBrown = {
  50: '#F7F6F5',
  100: '#EDEBE7',
  200: '#DBD7CD',
  300: '#C3BDAE',
  400: '#AEA38F',
  500: '#988973',
  600: '#8B7965',
  700: '#746353',
  800: '#5C4E42',
  900: '#443931',
  950: '#1D1611',
};

const aaOrange = {
  50: '#FFF5ED',
  100: '#FFE8D4',
  200: '#FFCEA8',
  300: '#FFAB70',
  400: '#FF7C37',
  500: '#FF5207',
  600: '#F03D06',
  700: '#C72A07',
  800: '#9E230E',
  900: '#7F200F',
  950: '#450C05',
};

const aaGreen = {
  50: '#EDFCF4',
  100: '#D3F8E2',
  200: '#AAF0CA',
  300: '#73E2AD',
  400: '#3BCC8B',
  500: '#17B272',
  600: '#0B905C',
  700: '#09734C',
  800: '#0A5C3E',
  900: '#094B34',
  950: '#042A1E',
};

const aaRed = {
  50: '#FFF1F1',
  100: '#FFE0E0',
  200: '#FFC6C6',
  300: '#FF9E9E',
  400: '#FF6767',
  500: '#FC3737',
  600: '#EA1A1A',
  700: '#C51010',
  800: '#A31111',
  900: '#861616',
  950: '#490606',
};

const aaBlue = {
  50: '#EFF5FF',
  100: '#DBE8FE',
  200: '#BFD7FE',
  300: '#93BBFD',
  400: '#609AFA',
  500: '#3B82F6',
  600: '#2570EB',
  700: '#1D64D8',
  800: '#1E55AF',
  900: '#1E478A',
  950: '#172E54',
};

const aaBase = {
  white: '#ffffff',
  black: '#000000',
};

const active = {
  1: aaOrange[50],
  2: aaOrange[100],
  3: aaOrange[400],
  4: aaOrange[500],
  5: aaOrange[900],
  6: aaOrange[950],
};

const neutral = {
  1: aaBrown[50],
  2: aaBrown[100],
  3: aaBrown[400],
  4: aaBrown[600],
  5: aaBrown[900],
  6: aaBrown[950],
};

const positive = {
  1: aaGreen[50],
  2: aaGreen[100],
  3: aaGreen[400],
  4: aaGreen[600],
  5: aaGreen[900],
  6: aaGreen[950],
};

const danger = {
  1: aaRed[50],
  2: aaRed[100],
  3: aaRed[400],
  4: aaRed[600],
  5: aaRed[900],
  6: aaRed[950],
};

const verified = {
  1: aaBlue[50],
  2: aaBlue[100],
  3: aaBlue[400],
  4: aaBlue[600],
  5: aaBlue[900],
  6: aaBlue[950],
};

module.exports = {
  aaColors: {
    white: aaBase.white,
    black: aaBase.black,
    transparent: 'transparent',
    active,
    verified,
    neutral,
    positive,
    danger,
  },
  aaBrown,
  aaOrange,
  aaGreen,
  aaRed,
  aaBlue,
  aaBase,
  active,
  neutral,
  positive,
  danger,
  verified,
};
