import React, { FC, useEffect, useRef } from 'react';

interface OnClickOutsideProps {
  className?: string;
  onClickOutside: () => void;
  children?: React.ReactNode;
}

export const ClickOutside: FC<OnClickOutsideProps> = ({
  children,
  className = '',
  onClickOutside,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, onClickOutside]);

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};
