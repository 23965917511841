import React from 'react';

export const FslightboxLoader: React.FC = () => {
  return (
    <div className="fslightbox-loader">
      <div className="fslightbox-loader-child-1" />
      <div className="fslightbox-loader-child-2" />
      <div className="fslightbox-loader-child-3" />
      <div className="fslightbox-loader-child-4" />
    </div>
  );
};
