import { isBrowser } from '@utils/isBrowser';

export const usePrefersReducedMotion = () => {
  if (!isBrowser()) return false;

  const hasNoPreference = window.matchMedia(
    '(prefers-reduced-motion: no-preference)',
  );

  return !hasNoPreference.matches;
};
