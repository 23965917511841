import classNames from 'classnames';

import { Image } from '../../../../components/Image';
import { AssetStoryblok } from '../../../../types/generated-storyblok';

export type InsightListProps = {
  image: AssetStoryblok;
  title: string;
  overline?: string;
};

export const InsightList: React.FC<InsightListProps> = ({
  image,
  overline,
  title,
}) => {
  return (
    <div
      className={classNames(
        'flex w-full items-center justify-center gap-x-3 overflow-hidden',
        classNames,
      )}
    >
      <div className="w-16 shrink-0">
        <Image
          alt={image.alt || title}
          focus={image.focus}
          hoverEffect
          shape="rounded"
          src={image.filename}
          width={64}
        />
      </div>

      <div className="flex w-full flex-col overflow-hidden transition duration-300 ease-out group-hover:text-neutral-4">
        <p className="truncate font-bold">{title}</p>
        {overline && <p className="truncate text-neutral-4">{overline}</p>}
      </div>
    </div>
  );
};
