import classNames from 'classnames';
import React, { forwardRef } from 'react';

import { Icon } from '../../Icon';
import styles from '../FieldWrapper/field.module.css';

interface Option
  extends Pick<React.OptionHTMLAttributes<HTMLOptionElement>, 'disabled'>,
    Pick<React.ReactElement, 'key'> {
  value: string | number;
}

interface InlineSelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'prefix'> {
  options: Option[];
  textAlign?: 'left' | 'center' | 'right';
}

export const InlineSelect = forwardRef<HTMLSelectElement, InlineSelectProps>(
  ({ id, name, options, textAlign = 'left', ...rest }, ref) => {
    const textAlignMapper = {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    };

    return (
      <div className={classNames(styles.UiInlineSelect)}>
        <select
          ref={ref}
          className={classNames(
            styles.UiInlineSelect__select,
            textAlignMapper[textAlign],
          )}
          id={id}
          name={name}
          {...rest}
        >
          {options.map((option) => (
            <option
              key={option.value}
              disabled={option.disabled}
              value={option.value}
            >
              {option.key}
            </option>
          ))}
        </select>
        <Icon
          className={classNames(styles.UiInlineSelect__chevron)}
          name="ic_chevron_down"
        />
      </div>
    );
  },
);
