import classNames from 'classnames';
import React from 'react';

import { Icon } from './Icon';
import styles from './icon.module.css';

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const Spinner = ({ size = 'sm' }: Props) => {
  return (
    <Icon
      className={classNames(styles.UiSpinner)}
      name="ic_spinner"
      size={size}
    />
  );
};
