import { ImageLoaderProps as NextImageLoaderProps } from 'next/legacy/image';

import { BREAKPOINTS, MAX_IMAGE_WIDTH_LG } from '../constants/breakpoints';

type ImageLoaderProps = NextImageLoaderProps & {
  height?: number;
  fit?: string;
};

// keeping track of how many variants we have
export type Ratio =
  | 'intrinsic'
  | '1/1'
  | '2/1'
  | '2/3'
  | '3/1'
  | '3/2'
  | '3/4'
  | '4/3'
  | '4/5'
  | '5/3'
  | '5/4'
  | '7/8'
  | '8/5'
  | '9/4'
  | '9/16'
  | '16/9';

export type Columns = 2 | 3 | 4 | 6 | 8 | 10 | 11 | 12;

export type ImageSize = {
  ratio: Ratio;
  span: Columns;
};

export type ImageSizes = {
  sm: ImageSize;
  md?: ImageSize;
  lg?: ImageSize;
};

const isStoryblokImageUrl = (url: string) => {
  return (
    url.startsWith('https://a.storyblok.com') ||
    url.startsWith('https://img2.storyblok.com')
  );
};

const isShopifyImageUrl = (url: string) => {
  return url.startsWith('https://cdn.shopify.com');
};

const isGCSImageUrl = (url: string) => {
  return url.startsWith('https://storage.googleapis.com');
};

const isAvantArteCDN = (url: string) => {
  return (
    url.includes('images.avantarte.org') ||
    url.includes('images-staging.avantarte.org')
  );
};

const pathAndExtensionForUrl = (pathname: string) => {
  const parts = pathname.split('.');
  const extension = parts.pop();
  const path = parts.join('.');
  return { path, extension };
};

const avantArteCDNLoader = (
  src: string,
  width: number,
  height?: number,
  quality?: number,
  fit?: string,
) => {
  const h = height ? `&h=${height}` : '';
  const q = quality ? `&q=${quality}` : '';
  const f = fit ? `&fit=${fit}` : '';
  return `${src}?w=${width}${h}${q}${f}`;
};

const storyblokLoader = (
  src: string,
  width: number,
  height?: number,
  quality?: number,
) => {
  const q = quality ? `/filters:quality(${quality})` : '';
  return `${src}/m/${width}x${height || 0}${q}`;
};

const shopifyImageLoader = (src: string, width: number) => {
  const { origin, pathname, search } = new URL(src);
  const { extension = '', path } = pathAndExtensionForUrl(pathname);
  return `${origin}${path}_${width}x.${extension}${search}`;
};

export const imageLoader = ({
  fit,
  height = 0,
  quality,
  src,
  width = 1024,
}: ImageLoaderProps) => {
  if (src && isStoryblokImageUrl(src)) {
    return storyblokLoader(src, width, height, quality);
  }
  if (src && isAvantArteCDN(src)) {
    return avantArteCDNLoader(src, width, height, quality, fit);
  }
  if (src && isShopifyImageUrl(src)) {
    return shopifyImageLoader(src, width);
  }
  if (src && isGCSImageUrl(src)) {
    // we pre-optimize images on GCS
    return src;
  }
  return src;
};

const getResponsiveSpan = (columns: Columns) => {
  switch (columns) {
    case 12:
      return '88vw';
    case 11:
      return '72vw';
    case 10:
      return '64vw';
    case 8:
      return '48vw';
    case 6:
      return '40vw';
    case 4:
      return '24vw';
    case 3:
      return '18vw';
    case 2:
      return '10vw';
    default:
      return '100vw';
  }
};

const getFixedSpan = (maxWidth: number, padding: number, columns: Columns) => {
  if (columns === 12) {
    return maxWidth;
  }
  const gap = padding * (columns - 1);
  const span = (maxWidth - gap) * (columns / 12);
  return Math.floor(span) || maxWidth;
};

export const imageSizes = (size: ImageSizes) => {
  let sizes = '';
  if (size.lg) {
    const lg = getFixedSpan(MAX_IMAGE_WIDTH_LG, 24, size.lg.span);
    sizes += `(min-width: ${BREAKPOINTS.LG}px) ${lg}px, `;
  }
  if (size.md) {
    const md = getResponsiveSpan(size.md.span);
    sizes += `(min-width: ${BREAKPOINTS.MD}px) ${md}, `;
  }
  if (size.sm) {
    sizes += getResponsiveSpan(size.sm.span);
  }
  return sizes;
};

// focus XxY:WxH
const storyblokImageFocus = (focus: string) => {
  const [coordinates] = focus.split(':');
  return coordinates.split('x');
};

// https://a.storyblok.com/f/../2400x1600/../..
const storyblokImageSize = (url: string) => {
  if (isStoryblokImageUrl(url)) {
    const found = url.split('/').find((part) => part.includes('x'));
    if (found) {
      return found.split('x');
    }
  }
  return [];
};

export const storyblokImagePosition = (src: string, focus?: string | null) => {
  if (focus && isStoryblokImageUrl(src)) {
    const [, height] = storyblokImageSize(src);
    const [, focusY] = storyblokImageFocus(focus);
    if (height && focusY) {
      const distance = Number(focusY) / Number(height);
      if (distance <= 1 / 3) return 'top';
      if (distance >= 2 / 3) return 'bottom';
    }
  }
  return 'center';
};
