import { FC } from 'react';

export interface Props {
  trackId: string;
  allowAutoplay: boolean;
}

export const SoundCloud: FC<Props> = ({ allowAutoplay, trackId }) => {
  const src = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${trackId}&color=%23ff5500&visual=true&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=true`;
  return (
    <div>
      <iframe
        allow={allowAutoplay ? 'autoplay' : ''}
        height="300"
        src={src}
        title={trackId}
        width="100%"
      />
    </div>
  );
};
