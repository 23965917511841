import Link from 'next/link';

import { CardWrapper } from '../../Containers';

type Props = {
  image: {
    filename: string;
    alt?: string;
    copyright?: string;
    focus?: string;
  };
  title: string;
  description: string;
  href: string;
  onClick?: () => void;
};

export const FranchiseCard = ({
  description,
  href,
  image,
  onClick,
  title,
}: Props) => {
  return (
    <Link className="group" href={href} onClick={onClick}>
      <CardWrapper
        image={image}
        imageSize={{
          sm: { ratio: '4/3', span: 12 },
          md: { ratio: '4/3', span: 6 },
          lg: { ratio: '4/3', span: 4 },
        }}
        variant="image"
      >
        <span className="flex aspect-[4/3] flex-col items-start justify-end gap-3 p-4 md:p-6">
          <span className="flex w-full max-w-xl flex-col">
            <span className="text-pretty text-md font-bold">{title}</span>
            <span className="text-pretty">{description}</span>
          </span>
        </span>
      </CardWrapper>
    </Link>
  );
};
