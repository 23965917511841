import classNames from 'classnames';

import { Image } from '../../../../components/Image';
import { Ratio } from '../../../../helpers/images';
import { AssetStoryblok } from '../../../../types/generated-storyblok';
import { Badge } from '../../Badge/Badge';
import { Pill } from '../../Pill';

export type InsightCardProps = {
  image: AssetStoryblok;
  imageRatio: Ratio;
  title: string;
  text: string;
  tag: string;
  overline?: string;
  mediaDuration?: string;
};

export const InsightCard: React.FC<InsightCardProps> = ({
  image,
  imageRatio,
  mediaDuration,
  overline,
  tag,
  text,
  title,
}) => {
  return (
    <div className={classNames('flex w-full flex-col gap-3', classNames)}>
      <div className="w-full overflow-hidden rounded-sm">
        <Image
          alt={image.alt || title}
          focus={image.focus}
          hoverEffect
          size={{
            sm: { ratio: imageRatio, span: 12 },
            md: { ratio: imageRatio, span: 6 },
            lg: { ratio: imageRatio, span: 4 },
          }}
          src={image.filename}
        />

        {mediaDuration && (
          <div className="absolute right-4 top-4">
            <Badge label={mediaDuration} />
          </div>
        )}
      </div>

      <div className="flex flex-col items-start gap-3 transition duration-300 ease-out group-hover:text-neutral-4">
        <Pill label={tag} />

        <div className="flex flex-col">
          <p className="font-bold">{title}</p>
          <p className="line-clamp-3">{text}</p>
        </div>

        {overline && <p className="text-neutral-4">{overline}</p>}
      </div>
    </div>
  );
};
