import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React, { useState } from 'react';

import { ClickOutside } from '../../../components/ClickOutside';
import { Button, ButtonSizeType, ButtonVariantType } from '../Button';
import styles from './dropdown.module.css';

interface DropdownProps {
  buttonLabel: string;
  buttonSize?: ButtonSizeType;
  buttonVariant?: ButtonVariantType;
  children?: React.ReactNode;
}

export const DropdownGroup = ({ children }: { children: React.ReactNode }) => {
  return <ul className={styles.UiDropdown__group}>{children}</ul>;
};

export const DropdownGroupItem = ({
  href,
  label,
  onClick,
  target,
}: {
  label: string;
  href: string;
  target?: '_blank';
  onClick?: () => void;
}) => {
  return (
    <li>
      <Link
        className={styles.UiDropdown__group__item}
        href={href}
        onClick={onClick}
        {...(target && { 'target': target })}
      >
        {label}
      </Link>
    </li>
  );
};

export const Dropdown: React.FC<DropdownProps> = ({
  buttonLabel,
  buttonSize = 'lg',
  buttonVariant = 'primary',
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative">
      <Button
        icon="ic_chevron_down"
        label={buttonLabel}
        onClick={() => setOpen(true)}
        size={buttonSize}
        variant={buttonVariant}
      />

      <AnimatePresence>
        {open && (
          <ClickOutside onClickOutside={() => setOpen(false)}>
            <motion.div
              animate={{ opacity: 1, transform: 'translate(-50%, 8px)' }}
              className={styles.UiDropdown}
              exit={{ opacity: 0, transform: 'translate(-50%, 48px)' }}
              initial={{ opacity: 0, transform: 'translate(-50%, 48px)' }}
            >
              {children}
            </motion.div>
          </ClickOutside>
        )}
      </AnimatePresence>
    </div>
  );
};
