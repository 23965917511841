import Player from '@vimeo/player';
import React, { useEffect, useRef } from 'react';

export type Props = {
  videoId: number;
  autoplay?: boolean;
  disabled?: boolean;
};

export const Vimeo: React.FC<Props> = ({
  autoplay = false,
  disabled = false,
  videoId,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);

  useEffect(() => {
    if (rootRef.current === null) return;

    playerRef.current ||= new Player(rootRef.current, {
      id: videoId,
      title: false,
      byline: false,
      portrait: false,
      responsive: true,
      autoplay,
    });

    if (disabled) {
      playerRef.current?.pause();
    }
  }, [autoplay, videoId, disabled]);

  return <div ref={rootRef} className="vimeo-container" />;
};
