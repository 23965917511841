import classNames from 'classnames';
import { FC } from 'react';

import { Icon } from '../../design-system/components/Icon';

interface Props {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  variant: 'light' | 'dark';
  label?: string;
}

export const VideoPlayButton: FC<Props> = ({
  className = '',
  disabled,
  label,
  onClick,
  variant,
}) => (
  <button
    className={classNames(
      'absolute inset-0 flex flex-col items-center justify-center gap-y-3 text-center',
      className,
    )}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    <span
      className={classNames('rounded-full', {
        'bg-white text-neutral-6': variant === 'light',
        'bg-neutral-6 text-white': variant === 'dark',
      })}
    >
      <Icon className="m-3" name="ic_play" size="md" />
    </span>
    {label && <span className="max-w-54 font-bold text-white">{label}</span>}
  </button>
);
