import { forwardRef } from 'react';

import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

export interface TextFieldProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'prefix'> {
  defaultValue?: string;
  label?: string;
  error?: string;
  hideErrorMessage?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  note?: string | React.ReactNode;
  dataTestid?: string;
  textAlign?: 'center' | 'left';

  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
}

export const TextField = forwardRef<HTMLTextAreaElement, TextFieldProps>(
  (
    {
      dataTestid,
      defaultValue,
      disabled = false,
      error,
      hideErrorMessage = false,
      id,
      label,
      name,
      note,
      prefix,
      required = false,
      rows = 2,
      suffix,
      textAlign = 'left',
      value,
      ...rest
    },
    ref,
  ) => {
    return (
      <FieldWrapper
        disabled={disabled}
        error={error}
        hideError={hideErrorMessage}
        id={id}
        label={label}
        note={note}
      >
        {prefix && <span>{prefix}</span>}
        <textarea
          ref={ref}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={textAlign === 'center' ? 'text-center' : 'text-left'}
          data-testid={dataTestid}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          rows={rows}
          value={value}
          {...rest}
        />
        {suffix && <span>{suffix}</span>}
      </FieldWrapper>
    );
  },
);
