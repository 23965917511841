import classNames from 'classnames';

import { CardWrapper } from '../../Containers';
import { Icon, type IconName } from '../../Icon';
import styles from '../cards.module.css';

type Props = {
  title: string;
  description: string;
  icon: IconName;
};

export const MenuCard = ({ description, icon, title }: Props) => {
  return (
    <CardWrapper variant="tertiary">
      <span className="flex aspect-[8/5] min-h-36 flex-col items-start justify-between gap-3 p-4 sm:aspect-[5/4] md:p-6">
        <span className={classNames(styles.UiMenuCard__icon)}>
          <Icon name={icon} />
        </span>
        <span className="flex w-full max-w-xl flex-col">
          <span className="text-pretty font-bold">{title}</span>
          <span className="text-pretty">{description}</span>
        </span>
      </span>
    </CardWrapper>
  );
};
