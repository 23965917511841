import classNames from 'classnames';
import React, { FC } from 'react';

import { Icon } from '../../Icon';
import styles from './checkbox.module.css';

interface CheckIconProps {
  isChecked: boolean;
  isDisabled: boolean;
  type: 'checkbox' | 'radio';
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const CheckIcon: FC<CheckIconProps> = ({
  isChecked,
  isDisabled,
  size = 'xs',
  type,
}) => {
  const sizeMapper = {
    'xs': 'h-5 w-5',
    'sm': 'h-6 w-6',
    'md': 'h-8 w-8',
    'lg': 'h-12 w-12',
  };

  return (
    <span
      className={classNames(
        isChecked ? styles.UiCheckicon__checked : styles.UiCheckicon__default,
        sizeMapper[size],
        type === 'radio' ? 'rounded-full' : 'rounded-sm',
        {
          'cursor-not-allowed opacity-50': isDisabled,
        },
      )}
    >
      <Icon
        className={classNames(
          styles.UiCheckicon__icon,
          isChecked ? 'scale-100 opacity-100' : 'scale-0 opacity-0',
        )}
        name="ic_check"
      />
    </span>
  );
};
