import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { forwardRef, ReactNode } from 'react';

import { Image } from '../../../../components/Image';
import styles from './checkbox.module.css';
import { CheckIcon } from './CheckIcon';

interface CheckboxProps {
  type: 'checkbox' | 'radio';
  id: string;
  label: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  description?: string;
  suffix?: string | React.ReactElement;
  thumbnail?: {
    filename: string;
    alt: string;
  };
  slot?: ReactNode;
  slotVisibleOnChecked?: boolean;
  dataTestid?: string;
}

const CheckboxCardItem = ({
  description,
  label,
  suffix,
}: {
  description?: string;
  label: string;
  suffix?: string | React.ReactElement;
}) => (
  <span className="flex w-full gap-3">
    <span className={classNames(styles.UiCheckboxCard__label)}>
      <span className={classNames(styles.UiCheckboxCard__label__value)}>
        {label}
      </span>

      {description ? (
        <span className={classNames(styles.UiCheckboxCard__label__description)}>
          {description}
        </span>
      ) : null}
    </span>

    {suffix ? (
      <span className={classNames(styles.UiCheckboxCard__suffix)}>
        {suffix}
      </span>
    ) : null}
  </span>
);

export const CheckboxCard = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked = false,
      dataTestid,
      description,
      disabled = false,
      id,
      label,
      name,
      onChange,
      slot,
      slotVisibleOnChecked = false,
      suffix,
      thumbnail,
      type = 'checkbox',
      value,
      ...rest
    },
    ref,
  ) => {
    return (
      <label
        className={classNames(
          checked
            ? styles.UiCheckboxCard__checked
            : styles.UiCheckboxCard__default,
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        )}
        htmlFor={id}
      >
        {thumbnail ? (
          <div className={classNames(styles.UiCheckboxCard__thumbnail)}>
            <Image
              alt={thumbnail.alt}
              className="h-full w-full object-cover"
              src={thumbnail.filename}
              width={64}
            />
          </div>
        ) : null}

        <div className="flex min-h-12 w-full flex-col justify-center">
          <div className="flex w-full gap-3 p-4">
            <CheckboxCardItem
              description={description}
              label={label}
              suffix={suffix}
            />

            <div className="relative shrink-0">
              <input
                ref={ref}
                checked={checked}
                className={classNames(styles.UiCheckboxCard__input)}
                data-testid={dataTestid}
                disabled={disabled}
                id={id}
                name={name}
                onChange={onChange}
                type={type}
                value={value}
                {...rest}
              />

              <CheckIcon
                isChecked={checked}
                isDisabled={disabled}
                type={type}
              />
            </div>
          </div>

          <AnimatePresence>
            {slot && (!slotVisibleOnChecked || checked) ? (
              <motion.div
                key="content"
                animate="open"
                className="w-full overflow-hidden"
                exit="collapsed"
                initial="collapsed"
                transition={{ duration: 0.3 }}
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 },
                }}
              >
                <div className="flex w-full px-4 pb-4">{slot}</div>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      </label>
    );
  },
);
