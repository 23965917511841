import classNames from 'classnames';
import React from 'react';

import styles from './grid.module.css';

type ColumnsType = 1 | 2 | 3 | 4;

export interface GridProps {
  columns: ColumnsType;
  className?: string;
  children?: React.ReactNode;
}

export const Grid: React.FC<GridProps> = ({ children, className, columns }) => (
  <div
    className={classNames(
      columns === 4 && styles.UiGrid__4,
      columns === 3 && styles.UiGrid__3,
      columns === 2 && styles.UiGrid__2,
      columns === 1 && styles.UiGrid__1,
      className,
    )}
  >
    {children}
  </div>
);
