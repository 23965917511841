import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { FslightboxLoader } from '../FslightboxLoader';
import { Vimeo } from '../internal/Vimeo';
import { Youtube } from '../internal/Youtube/Youtube';
import type { LightboxMediaVimeo, LightboxMediaYoutube } from '../types';

// When displaying multiple videos in a lightbox, we only want the currently
// viewed Vimeo video to be playing. Since fslightbox-react doesn't have an
// onChange callback, and isn't a controlled component, we have to find out
// whether the video is currently shown a different way.
//
// This traverses the DOM to check if the current video is the active slide,
// by checking whether a parent element's transform property is set to
// `translateX(0px)`. It then creates a MutationObserver to update the
// `isActiveSlide` state anytime that transform property changes.
const useIsActiveSlide = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isActiveSlide, setIsActiveSlide] = useState(false);

  useEffect(() => {
    if (elementRef.current === null) return () => {};

    const slideContainer =
      elementRef.current?.parentElement?.parentElement?.parentElement;

    if (!slideContainer) return () => {};

    setIsActiveSlide(slideContainer.style.transform === 'translateX(0px)');

    const observer = new MutationObserver(() => {
      setIsActiveSlide(slideContainer.style.transform === 'translateX(0px)');
    });

    observer.observe(slideContainer, {
      attributes: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    isActiveSlide,
    ref: elementRef,
  };
};

type Props = LightboxMediaVimeo & {
  autoplay: boolean;
};

export const LightboxVimeo: React.FC<Props> = ({ autoplay, id }) => {
  const { isActiveSlide, ref } = useIsActiveSlide();

  return (
    <div ref={ref} className="max-h-[90vh] w-[1080px] max-w-[90vw]">
      <FslightboxLoader />
      <Vimeo
        autoplay={autoplay}
        disabled={!isActiveSlide}
        videoId={Number(id)}
      />
    </div>
  );
};

export const LightboxYoutube: React.FC<LightboxMediaYoutube> = ({
  baseUrl,
  id,
  ratio,
  title,
}) => {
  const { isActiveSlide, ref } = useIsActiveSlide();

  return (
    <div
      ref={ref}
      className={classNames('max-h-[90vh] w-[1080px] max-w-[90vw]', {
        'aspect-[16/9]': ratio === '16/9',
        'aspect-[9/16]': ratio === '9/16',
      })}
    >
      {isActiveSlide && (
        <Youtube baseUrl={baseUrl} title={title} videoId={id} />
      )}
    </div>
  );
};
