import Link from 'next/link';
import React from 'react';

type Props = {
  handleOnClick?: (index: number) => void;
  isCurrentPage: boolean;
  page: number;
  paginationUrl: string;
  queryParams?: string;
};

export const PageNumber: React.FC<Props> = ({
  handleOnClick,
  isCurrentPage,
  page,
  paginationUrl,
  queryParams,
}) => (
  <>
    {isCurrentPage ? (
      <span className="flex h-9 w-9 items-center justify-center text-center text-neutral-4">
        {page}
      </span>
    ) : (
      <Link
        className="link flex h-9 w-9 items-center justify-center text-center max-sm:hidden"
        href={`${paginationUrl}/${page}${queryParams}`}
        onClick={() => handleOnClick?.(page)}
        prefetch={false}
      >
        {page}
      </Link>
    )}
  </>
);
