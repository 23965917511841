export const productUrl = (handle: string, type: string): string => {
  handle = handle.replace('products/', '');

  if (type === 'Collection') {
    return `/series/${handle}`;
  }
  if (type === 'NFT') {
    return `/nft/${handle}`;
  }

  return `/products/${handle}`;
};
