export const BREAKPOINTS = {
  MD: 768,
  LG: 1024,
};

export const HEADER_HEIGHTS = {
  MOBILE: 60,
  DESKTOP: 72,
};

// Based on maximum width of the page, with padding
export const MAX_IMAGE_WIDTH_LG = 1584;
