import { lpad } from '@utils/strings';
import React from 'react';

import { VideoPlayButton } from '../../../components/InlineVideo/VideoPlayButton';
import { Badge } from '../Badge/Badge';

type VideoContainerProps = {
  mediaComponent: React.ReactNode;
  disabled: boolean;
  label?: string;
  duration?: number;
  onClick: () => void;
};

function formatSecondsDuration(duration: number) {
  const minutes = lpad(Math.floor(duration / 60).toString(), '0', 2);
  const seconds = lpad((duration % 60).toString(), '0', 2);

  return `${minutes}:${seconds}`;
}

export const VideoContainer: React.FC<VideoContainerProps> = ({
  disabled,
  duration,
  label,
  mediaComponent,
  onClick,
}) => {
  return (
    <div className="relative h-full w-full">
      <div className="w-full">{mediaComponent}</div>

      <span className="absolute inset-0 bg-neutral-6/40 transition duration-300 ease-out group-hover:bg-neutral-6/0" />

      {duration ? (
        <span className="absolute right-4 top-4">
          <Badge label={formatSecondsDuration(duration)} />
        </span>
      ) : null}

      <VideoPlayButton
        disabled={disabled}
        label={label}
        onClick={onClick}
        variant="light"
      />
    </div>
  );
};
