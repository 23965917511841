import latinise from 'latinize';

export const lpad = (str: string, pad: string, len: number): string =>
  str.length >= len ? str : lpad(pad + str, pad, len);

export const normaliseString = (str: string): string =>
  latinise(str?.trim().toLowerCase() ?? '');

export const capitalize = (str: string): string => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removePunctuation = (input = '') => {
  return input.replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g, '');
};

export const underscoreToSpace = (str: string) => {
  return str.replace(/_/g, ' ');
};

export const includes = (str: string | null, value: string) => {
  return str?.toLowerCase().includes(value.toLowerCase());
};

export const truncateString = (str = '', max = 0) => {
  return max ? str.slice(0, max) : str;
};

export const getFirstAndLastWords = (str?: string) => {
  const [first, ...remaining] = str ? str.split(' ') : [];
  const last = remaining.join(' ');
  return [first, last];
};

export function getInitials(input: string, limit?: number) {
  let substring = input.split(' ');

  if (limit) {
    substring = substring.splice(0, limit);
  }

  return substring.map((name) => name.charAt(0)).join('');
}
