import classNames from 'classnames';
import Link from 'next/link';

import { ImageSizes } from '../../../../helpers/images';
import { Button } from '../../Button';
import { CardWrapper } from '../../Containers';
import { Pill } from '../../Pill/Pill';

interface BannerCardBase {
  title: string;
  description?: string;
  pillLabel?: string;
  pillIsActive?: boolean;
  hasLargeTitle?: boolean;
  aspectRatioClassName: string;
  onClick?: () => void;
}

type SolidInterface = BannerCardBase & {
  variant: 'primary' | 'secondary' | 'tertiary';
};

type ImageInterface = BannerCardBase & {
  variant: 'image';
  image: {
    filename: string;
    alt?: string;
    copyright?: string;
    focus?: string;
  };
  imageSize?: ImageSizes;
  imageWidth?: number;
};

type BannerCardProps = SolidInterface | ImageInterface;

const isImage = (props: BannerCardProps): props is ImageInterface => {
  return props.variant === 'image';
};

type HrefInterface = React.HTMLAttributes<HTMLAnchorElement> &
  BannerCardProps & {
    as?: 'a';
    href: string;
    target?: '_self' | '_blank';
    rel?: 'noreferrer';
    hideIcon?: boolean;
  };

type ButtonInterface = React.HTMLAttributes<HTMLButtonElement> &
  BannerCardProps & {
    as?: 'button';
    type?: 'button';
    disabled?: boolean;
    buttonLabel: string;
  };

type BannerCardInterface = HrefInterface | ButtonInterface;

const isHref = (props: BannerCardInterface): props is HrefInterface => {
  return props.as === 'a';
};

const BannerCardItemDetails = (props: BannerCardInterface) => {
  if (isHref(props)) {
    const { description, hasLargeTitle, pillIsActive, pillLabel, title } =
      props;

    return (
      <div className="flex h-full w-full flex-col items-start justify-end gap-3 p-4 md:p-6">
        {pillLabel && <Pill isActive={pillIsActive} label={pillLabel} />}

        <div className="flex w-full max-w-md flex-col">
          <p
            className={classNames(
              'text-balance font-bold',
              hasLargeTitle ? 'text-md md:text-lg' : 'text-md',
            )}
          >
            {title}
          </p>
          {description ? <p className="text-pretty">{description}</p> : null}
        </div>
      </div>
    );
  }

  const {
    buttonLabel,
    description,
    hasLargeTitle,
    onClick,
    pillIsActive,
    pillLabel,
    title,
    variant,
  } = props;

  const variantMapper = {
    primary: 'tertiary',
    secondary: 'primary',
    tertiary: 'primary',
    image: 'tertiary',
  } as const;

  return (
    <div className="flex h-full w-full flex-col items-start justify-end gap-3 p-4 md:p-6">
      {pillLabel && <Pill isActive={pillIsActive} label={pillLabel} />}

      <div className="flex w-full max-w-md flex-col">
        <p
          className={classNames(
            'text-balance font-bold',
            hasLargeTitle ? 'text-md md:text-lg' : 'text-md',
          )}
        >
          {title}
        </p>
        {description ? <p className="text-pretty">{description}</p> : null}
      </div>

      <Button
        label={buttonLabel}
        onClick={onClick}
        variant={variantMapper[variant]}
      />
    </div>
  );
};

const BannerCardItem = (props: BannerCardInterface) => {
  if (isImage(props)) {
    const { image, imageSize, imageWidth, variant } = props;

    return (
      <CardWrapper
        image={image}
        {...(imageSize && { 'imageSize': imageSize })}
        {...(imageWidth && { 'imageWidth': imageWidth })}
        variant={variant}
      >
        <BannerCardItemDetails {...props} />
      </CardWrapper>
    );
  }

  const { variant } = props;

  return (
    <CardWrapper variant={variant}>
      <BannerCardItemDetails {...props} />
    </CardWrapper>
  );
};

export const BannerCard = (props: BannerCardInterface) => {
  if (isHref(props)) {
    const { aspectRatioClassName, href, onClick, rel, target } = props;

    return (
      <Link
        {...(target && { 'target': target })}
        {...(rel && { 'rel': rel })}
        className={classNames(
          aspectRatioClassName,
          'group relative block w-full',
        )}
        href={href}
        onClick={onClick}
      >
        <BannerCardItem {...props} />
      </Link>
    );
  }

  const { aspectRatioClassName } = props;

  return (
    <div className={classNames(aspectRatioClassName, 'relative block w-full')}>
      <BannerCardItem {...props} />
    </div>
  );
};
