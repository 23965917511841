import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { ImageContainer } from './ImageContainer';
import { VideoContainer } from './VideoContainer';

type AspectRatio =
  | 'threeToFour'
  | 'nineToSixteen'
  | 'twoToThree'
  | 'square'
  | 'none';

type MediaProps =
  | {
      type: 'Image';
      href?: string;
      openLinkInTab?: boolean;
      disabled?: never;
      onClick?: never;
      duration?: never;
    }
  | {
      type: 'Video';
      href?: never;
      openLinkInTab?: never;
      disabled?: boolean;
      duration?: number;
      onClick?: () => void;
    };

type Props = MediaProps & {
  mediaComponent: ReactNode;
  title?: string;
  className?: string;
  caption?: string;
  aspectRatio?: AspectRatio;
};

export const MediaContainer: React.FC<Props> = ({
  aspectRatio = 'none',
  caption,
  className,
  disabled = false,
  duration,
  href,

  mediaComponent,
  onClick = () => {},
  openLinkInTab,
  title,
  type,
}) => {
  return (
    <figure className="flex w-full flex-col gap-3">
      <div
        className={classNames(
          'group relative z-10 w-full overflow-hidden rounded-sm',
          {
            'aspect-[4/3]': aspectRatio === 'threeToFour',
            'aspect-[16/9]': aspectRatio === 'nineToSixteen',
            'aspect-[3/2]': aspectRatio === 'twoToThree',
            'aspect-[1/1]': aspectRatio === 'square',
            'aspect-none': aspectRatio === 'none',
          },
          className,
        )}
      >
        {type === 'Image' ? (
          <ImageContainer
            href={href}
            mediaComponent={mediaComponent}
            openLinkInTab={openLinkInTab}
          />
        ) : (
          <VideoContainer
            disabled={disabled}
            duration={duration}
            label={title}
            mediaComponent={mediaComponent}
            onClick={onClick}
          />
        )}
      </div>

      {caption ? (
        <figcaption className="w-full text-neutral-4">
          <p>{caption}</p>
        </figcaption>
      ) : null}
    </figure>
  );
};
