import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import { Image } from '../../../../components/Image';
import { ImageSizes } from '../../../../helpers/images';
import { storyblokMediaItemToLightboxEntry } from '../../../../utils/lightbox';
import buttonStyles from '../../Button/button.module.css';
import { Icon } from '../../Icon';
import { Lightbox } from '../../Lightbox';
import { MediaContainer } from '../../MediaContainer';
import carouselStyles from '../splide.module.css';

type Props = {
  images: MultiMediaCarouselItem[];
  onClick?: (properties: Record<string, string | number>) => void;
  onSlide?: (index: number) => void;
  resetTrigger?: number;
};

export const ArtistImageGallery: React.FC<Props> = ({
  images,
  onClick,
  onSlide = () => {},
  resetTrigger,
}) => {
  const lightboxItems = React.useMemo(
    () => images.map(storyblokMediaItemToLightboxEntry),
    [images],
  );

  const mainRef = React.useRef<Splide>(null);

  // Rewind the carousel
  useEffect(() => {
    const splideElement = mainRef.current;

    if (splideElement) {
      splideElement.go(0);
    }
  }, [images, resetTrigger]);

  return (
    <Lightbox items={lightboxItems}>
      {(lightbox) => (
        <div className={classNames(carouselStyles.UiArtistImageGallery)}>
          <Splide
            ref={mainRef}
            hasTrack={false}
            onMoved={(e) => {
              onSlide(e.index);
            }}
            options={{
              rewind: false,
              pagination: false,
              mediaQuery: 'min',

              arrows: false,
              focus: 'center',
              gap: 12,
              perMove: 1,
              perPage: 2,

              flickPower: 300,
              flickMaxPages: 0.3,

              breakpoints: {
                768: {
                  arrows: images.length > 1,
                  gap: 20,
                  perPage: 1,
                },
              },
            }}
          >
            <div
              className={classNames(
                'splide__arrows',
                carouselStyles.UiArtistImageGallery__arrows,
              )}
            >
              <button
                className={classNames(
                  'splide__arrow splide__arrow--prev z-50 -translate-x-16 !rounded-full p-2',
                  buttonStyles.UiButton__variant__tertiary,
                )}
                type="submit"
              >
                <Icon name="ic_arrow_left" />
              </button>
              <button
                className={classNames(
                  'splide__arrow splide__arrow--next z-50 translate-x-16 !rounded-full p-2',
                  buttonStyles.UiButton__variant__tertiary,
                )}
                type="submit"
              >
                <Icon name="ic_arrow_right" />
              </button>
            </div>

            <SplideTrack
              className={classNames(carouselStyles.UiArtistImageGallery__track)}
            >
              {images.map((media, index) => {
                const sizes = {
                  sm: { ratio: '1/1', span: 12 },
                  md: { ratio: '3/2', span: 6 },
                  lg: { ratio: '3/2', span: 8 },
                } as ImageSizes;

                if (media.component === 'image') {
                  return (
                    <SplideSlide
                      key={media._uid}
                      className={classNames(
                        carouselStyles.UiArtistImageGallery__track__slide,
                      )}
                    >
                      <div className="w-full">
                        <MediaContainer
                          mediaComponent={
                            <Image
                              alt={media.image.alt}
                              eagerLoading={index === 0}
                              focus={media.image.focus}
                              onClick={() => {
                                lightbox.openOnSlide(index);
                                onClick?.({
                                  position: index,
                                  title: media.image.title,
                                  type: 'image',
                                });
                              }}
                              shape="rounded"
                              size={sizes}
                              src={media.image.filename}
                            />
                          }
                          type="Image"
                        />
                      </div>
                    </SplideSlide>
                  );
                }

                return (
                  <SplideSlide
                    key={media._uid}
                    className={classNames(
                      carouselStyles.UiArtistImageGallery__track__slide,
                    )}
                  >
                    <MediaContainer
                      mediaComponent={
                        <Image
                          alt={media.coverImage?.alt}
                          eagerLoading={index === 0}
                          focus={media.coverImage.focus}
                          onClick={() => {
                            lightbox.openOnSlide(index);
                            onClick?.({
                              position: index,
                              title: media.coverImage?.title,
                              type: 'video',
                            });
                          }}
                          shape="rounded"
                          size={sizes}
                          src={media.coverImage?.filename}
                        />
                      }
                      type="Video"
                    />
                  </SplideSlide>
                );
              })}
            </SplideTrack>
          </Splide>
        </div>
      )}
    </Lightbox>
  );
};
