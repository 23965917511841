import { useState } from 'react';

import { UseLightboxHook } from './types';

export const DEFAULT_CONFIG = {
  items: [],
};

export const useLightbox: UseLightboxHook = (options = DEFAULT_CONFIG) => {
  const [visible, setVisibleState] = useState(false);
  const [slide, setSlideState] = useState<number>(options.items[0]?.key || 0);
  const { items } = options;

  const slideExists = (key: number) => items.some((i) => i.key === key);

  const setVisible = (value: boolean) => {
    setVisibleState(value);
  };

  const setSlide = (key: number) => {
    if (slideExists(key)) {
      setSlideState(key);
    }
  };

  const openOnSlide = (key: number) => {
    if (slideExists(key)) {
      setSlideState(key);
      setVisible(true);
    }
  };

  return {
    visible,
    slide,
    items,
    setVisible,
    setSlide,
    openOnSlide,
  };
};
