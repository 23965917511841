import classNames from 'classnames';

import { PulsingDot } from '../Icon';
import { Skeleton } from '../Skeleton/Skeleton';
import styles from './pill.module.css';

type Props = {
  label: React.ReactNode | string;
  isActive?: boolean;
  isLoading?: boolean;
};

export const Pill = ({ isActive = false, isLoading = false, label }: Props) => {
  return (
    <div className={classNames(styles.UiPill)}>
      {isActive ? <PulsingDot /> : null}
      {isLoading ? (
        <Skeleton className="mx-1 h-5 w-16" />
      ) : (
        <span className={classNames(styles.UiPill__label)}>{label}</span>
      )}
    </div>
  );
};
