import classNames from 'classnames';
import React from 'react';

import { Icon } from './Icon';
import styles from './icon.module.css';

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const PulsingDot: React.FC<Props> = ({ size = 'sm' }) => (
  <span className={classNames(styles.UiPulsingDot)}>
    <Icon name="ic_live" size={size} />
    <Icon
      className={classNames(styles.UiPulsingDot__animate)}
      name="ic_live"
      size={size}
    />
  </span>
);
