import { productUrl } from '@utils/productUrl';
import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { GeneratedReviewDetailsFragment } from '../../../types/generated';

type Props = {
  review: GeneratedReviewDetailsFragment;
  $isDark?: boolean;
};

export const ReviewCard: React.FC<Props> = ({ $isDark = false, review }) => {
  const { t } = useTranslation('product');

  if (!review) {
    return null;
  }

  const url = review.product
    ? productUrl(review.product?.slug, 'Product')
    : null;

  return (
    <blockquote
      className={classNames(
        'flex flex-col space-y-5 rounded-sm border border-divider/20 p-6',
        $isDark ? 'bg-neutral-6' : 'bg-white',
      )}
    >
      <div className="flex flex-col space-y-3">
        <span
          className={classNames(
            'h-5 w-5',
            $isDark ? 'text-neutral-3' : 'text-neutral-4',
          )}
        >
          <svg viewBox="0 0 20 20">
            <path
              d="M9.123 6.409V2C3.457 2.198 1 5.17 1 11.56V18h8.123c-.05-1.189-.1-2.328-.1-3.864 0-1.634.05-2.774.1-3.963H4.71v-.346c0-2.527 1.906-3.369 4.413-3.418M10.877 18H19c-.05-1.189-.1-2.328-.1-3.864 0-1.634.05-2.774.1-3.963h-4.412v-.346c0-2.527 1.905-3.369 4.412-3.418V2c-5.666.198-8.123 3.17-8.123 9.56z"
              fill="currentColor"
            />
          </svg>
        </span>
        <p className={classNames($isDark ? 'text-white' : 'text-neutral-6')}>
          {review.freeText}
        </p>
      </div>

      {review.product?.title && url && (
        <footer
          className={classNames($isDark ? 'text-neutral-3' : 'text-neutral-4')}
        >
          {t('reviews.cardLabel')}{' '}
          <Link
            className="hyperlink"
            href={url}
            prefetch={false}
            target="_blank"
          >
            {review.product?.title}
          </Link>
        </footer>
      )}
    </blockquote>
  );
};
