import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Portal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return mounted
    ? ReactDOM.createPortal(<div>{children}</div>, document.body)
    : null;
};

export default Portal;
