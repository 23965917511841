import classNames from 'classnames';
import React, { forwardRef } from 'react';

import styles from './checkbox.module.css';
import { CheckIcon } from './CheckIcon';

interface CheckboxProps {
  type: 'checkbox' | 'radio';
  id: string;
  label: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  iconPosition?: 'left' | 'right';
  description?: string;
  suffix?: string;
  dataTestid?: string;
}

const CheckboxCardItem = ({
  description,
  label,
  suffix,
}: {
  description?: string;
  label: string;
  suffix?: string;
}) => (
  <span className="flex w-full gap-3">
    <span className={classNames(styles.UiCheckbox__label)}>
      <span className={classNames(styles.UiCheckbox__label__value)}>
        {label}
      </span>

      {description ? (
        <span className={classNames(styles.UiCheckbox__label__description)}>
          {description}
        </span>
      ) : null}
    </span>

    {suffix ? (
      <span className={classNames(styles.UiCheckbox__suffix)}>{suffix}</span>
    ) : null}
  </span>
);

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked = false,
      dataTestid,
      description,
      disabled = false,
      iconPosition = 'left',
      id,
      label,
      name,
      onChange,
      suffix,
      type = 'checkbox',
      value,
      ...rest
    },
    ref,
  ) => {
    return (
      <label
        className={classNames(
          checked ? styles.UiCheckbox__checked : styles.UiCheckbox__default,
          iconPosition === 'right' ? 'flex-row-reverse' : 'flex-row',
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        )}
        htmlFor={id}
      >
        <span className="relative h-full shrink-0">
          <input
            ref={ref}
            checked={checked}
            className={classNames(styles.UiCheckbox__input)}
            data-testid={dataTestid}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            type={type}
            value={value}
            {...rest}
          />

          <CheckIcon isChecked={checked} isDisabled={disabled} type={type} />
        </span>

        <CheckboxCardItem
          description={description}
          label={label}
          suffix={suffix}
        />
      </label>
    );
  },
);
