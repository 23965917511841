import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import carouselStyles from '../splide.module.css';

type Props = {
  items: Array<React.ReactNode>;
  resetTrigger?: number;
  onSlide?: (index: number) => void;
  gridCols?: 3 | 4;
};

export const GridCarousel: React.FC<Props> = ({
  gridCols = 4,
  items,
  onSlide = () => {},
  resetTrigger,
}) => {
  const mainRef = React.useRef<Splide>(null);

  // Rewind the carousel
  useEffect(() => {
    const splideElement = mainRef.current;

    if (splideElement) {
      splideElement.go(0);
    }
  }, [items, resetTrigger]);

  return (
    <div className={classNames(carouselStyles.UiGridCarousel)}>
      <Splide
        ref={mainRef}
        hasTrack={false}
        onMoved={(e) => {
          onSlide(e.index);
        }}
        options={{
          rewind: false,
          pagination: false,
          mediaQuery: 'min',

          arrows: false,
          focus: 0,
          gap: 12,
          perMove: 1,
          perPage: 2,

          flickPower: 300,
          flickMaxPages: 0.3,
          dragMinThreshold: {
            mouse: 10,
            touch: 30,
          },

          breakpoints: {
            768: {
              destroy: true,
            },
          },
        }}
      >
        <SplideTrack
          className={classNames(carouselStyles.UiGridCarousel__track)}
        >
          {items.map((item, index) => (
            <SplideSlide
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classNames(
                carouselStyles.UiGridCarousel__track__slide,
                gridCols === 3
                  ? 'md:col-span-6 lg:col-span-4'
                  : 'md:col-span-4 lg:col-span-3',
              )}
            >
              <div className="w-full">{item}</div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  );
};
