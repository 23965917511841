import classNames from 'classnames';

import styles from './skeleton.module.css';

type Props = {
  className?: string;
};

export const Skeleton = ({ className }: Props) => {
  return <span className={classNames(styles.UiSkeleton, className)} />;
};
