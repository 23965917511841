import classNames from 'classnames';

import { Button } from '../../Button';
import styles from '../FieldWrapper/field.module.css';

type Props = {
  disabled: boolean;
  max: number;
  value: number;
  onChange(value: number): void;
};

export const IncrementField = ({ disabled, max, onChange, value }: Props) => {
  const decrementDisabled = disabled || value === 1;
  const incrementDisabled = disabled || value === max;

  const handleDecrement = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };

  const handleIncrement = () => {
    if (value !== max) {
      onChange(value + 1);
    }
  };

  return (
    <div
      className={classNames(
        disabled ? styles.UiIncrementField__disabled : styles.UiIncrementField,
      )}
    >
      <Button
        disabled={decrementDisabled}
        hideLabel
        icon="ic_minus"
        label="Decrement"
        onClick={handleDecrement}
        size="xs"
        type="button"
        variant="transparent"
      />
      <span className={classNames(styles.UiIncrementField__number)}>
        {value}
      </span>
      <Button
        disabled={incrementDisabled}
        hideLabel
        icon="ic_add"
        label="Increment"
        onClick={handleIncrement}
        size="xs"
        type="button"
        variant="transparent"
      />
    </div>
  );
};
